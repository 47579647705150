import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMoment from "vue-moment";

import router from "./router.js";
import store from "./store";
import App from "./App.vue";

import APIService from "./services/api";

import BaseInput from "./components/UI/BaseInput.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseSpinner from "./components/UI/BaseSpinner.vue";
import BasePagination from "./components/UI/BasePagination.vue";

import CommonMenu from "./components/common/menu.vue";
import CommonLoading from "./components/common/loading.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import AmountFormat from "./plugins/amount_format";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMoment);
Vue.use(AmountFormat);

Vue.component("base-input", BaseInput);
Vue.component("base-button", BaseButton);
Vue.component("base-spinner", BaseSpinner);
Vue.component("base-pagination", BasePagination);
Vue.component("common-menu", CommonMenu);
Vue.component("common-loading", CommonLoading);

new APIService();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
