var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
      'btn',
      {
        primaryBtn: _vm.stylevariant === 'primary',
        secondaryBtn: _vm.stylevariant === 'secondary',
      },
    ],style:(_vm.cus_style),attrs:{"type":_vm.type},on:{"click":_vm.onClick}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(_vm.loading)?_c('b-spinner',{staticStyle:{"margin-right":"0.5rem"},attrs:{"small":""}}):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }