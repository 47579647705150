import Vue from "vue";

class AuthService {
  static login(payload) {
    const url = "auth/login";

    return Vue.axios.post(url, payload);
  }

  static forgotPassword(payload) {
    const url = "auth/forgot-password";

    return Vue.axios.post(url, payload);
  }

  static changePassword(payload) {
    const url = "auth/change-password";

    return Vue.axios.post(url, payload);
  }

  static resetPassword(slug, payload) {
    const url = `auth/reset-password/${slug}`;

    return Vue.axios.post(url, payload);
  }

  static verifyResetPasswordSlug(slug) {
    const url = `auth/reset-password/${slug}`;

    return Vue.axios.get(url);
  }

  static getGuestUserEmail(slug) {
    const url = `auth/${slug}/email`;

    return Vue.axios.get(url);
  }

  static requestOtp(slug) {
    const url = `auth/${slug}/otp`;

    return Vue.axios.get(url);    
  }

  static loginOtp(payload) {
    const slug = payload.slug;
    const url = `auth/${slug}/login-otp`;
    return Vue.axios.post(url, payload);
  }
}

export default AuthService;
