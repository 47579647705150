<template>
  <div id="dashboard-container" align-h="center">
    <div class="guest-view-header">
      <img src="../../assets/capifly_logo_on-dark.png" class="capifly-logo"/>
      <div class="guest-company-name">{{ $store.getters.user.company_name}}</div>
      <div class="guest-hello-1">Hello {{ $store.getters.user.name }},</div>
      <div class="guest-hello-2">The {{ $store.getters.user.company_name}} Analysis Report is ready.</div>
      <div class="guest-hello-3">
        <p>We hope this report provides valuable insight into your company’s performance.</p>
        <p>Thank you for choosing Capifly. Please contact us if you have any questions.</p>
      </div>      
    </div>
    <b-row class="guest-report-summary">
      <b-col class="summary-screening-score">
        <div class="guest-report-summary-title">Screening Score</div>
        <div class="guest-report-summary-value">{{data?(data.score[0].toUpperCase() + data.score.substr(1).toLowerCase()):'N/A'}}</div>
      </b-col>
      <b-col class="guest-report-summary-col">
        <div class="guest-report-summary-title">ARR</div>
        <div class="guest-report-summary-value">$ {{data? (new Intl.NumberFormat().format(data.arr)):'N/A' }} </div>        
      </b-col>
      <b-col class="guest-report-summary-col col-border">
        <div class="guest-report-summary-title">Active Contracts</div>
        <div class="guest-report-summary-value">{{data?data.contracts:'N/A'}}</div>
      </b-col>      
      <b-col class="guest-report-summary-col col-border">
        <div class="guest-report-summary-title">Stage</div>
        <div class="guest-report-summary-value">{{ this.stageText}}</div>        
      </b-col>
    </b-row>
    <div class="back-link" @click.stop="goBack" v-if="false">
      <img src="/images/Component 593 – 171.png" alt="Component 593 – 171" />
    </div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>

    <div v-if="querying">
      <common-loading />
    </div>
    <!-- <div>
      <welcome-message :app_data="data"/>
    </div> -->
    <!-- <div v-else-if="data && data.state">
      <offer-letter
        :app_data="data"
        :charts="data.charts"
        v-if="data.state === 'QUALIFIED' || data.state === 'EXECUTION'"
      />
      <offer-letter-1
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'OfferLetter1'"
      />
      <screening-stage
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'SCREENING'"
      />
      <Gathering
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'GATHERING'"
      />
      <not-qualified
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'DISQUALIFIED'"
      />
      <funded-stage
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'FUNDED' || data.state === 'COMPLETED'"
      />
      <application-canceled
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'CANCELED'"
      />
      <div v-else class="text-center p-2">{{ data.state }}</div>
    </div> -->
    <charts-page
      v-if="
        !querying &&
        data &&
        data.state !== 'CANCELED' &&
        data.charts &&
        data.charts.length
      "
      :style="`opacity: ${data && data.state === 'CANCELED' ? 0.1 : 1}`"
      :app_data="data"
      :charts="data.charts"
    />
    <cohorts-arr
      v-if="data && data.cohorts && data.cohorts.length"
      :app_data="data"
    />

    <cohorts-logos
      v-if="data && data.cohorts && data.cohorts.length"
      :app_data="data"
    />
  </div>
</template>

<script>
import ApplicationsService from "@/services/applications.service";

import ChartsPage from "../../ui-components/application/Charts.vue"
import CohortsArr from "../../ui-components/application/CohortsArr.vue"
import CohortsLogos from "../../ui-components/application/CohortsLogos.vue"

export default {
  name: "guestview",
  components: {
    // ScreeningStage,
    // NotQualified,
    // FundedStage,
    // ApplicationCanceled,
    ChartsPage,
    CohortsArr,
    CohortsLogos,
    // Gathering,
    // BaseButton,
    // WelcomeMessage
  },
  data() {
    return {
      id: null,
      error: null,
      querying: false,
      data: null,
      stageText: "NA"
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    query(id) {
      this.error = null;
      this.querying = true;

      ApplicationsService.getGuestApp(id)
        .then((data) => {
          if (!data) {
            this.error = "No application found.";
          } else {
            this.data = data;
            if (data.stage == null ) {
              this.stageText = "NA";
            } else {
              this.stageText = data.stage; //data.stage[0].toUpperCase() + data.stage.substring(1).toLocaleLowerCase();
            }
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading application.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    openSeprateLink() {
      window.open("https://www.growthcapifly.com/upload-documents", "_blank");
    },
  },
  watch: {
    // $route(val) {
    //   if (!this.id || this.id !== val.params.id) {
    //     this.id = val.params.id;
    //     this.query(val.params.id);
    //   }
    // },
  },
  mounted() {
    this.id = this.$route.params.slug;
    this.query(this.$route.params.slug);
  },
  updated() {
    //TODO: Remove all the comments from the file
    if (!this.id || this.id !== this.$route.params.slug) {
      this.id = this.$route.params.slug;
      this.query(this.$route.params.slug);
    }
  },
};
</script>

<style scoped>
#dashboard-container {
  max-width: 967px;
  margin-left: auto;
  margin-right:auto;
}
#dashboard-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
}
#dashboard-container .new-app-link {
  font-size: 15px;
}
#dashboard-container .back-link {
  cursor: pointer;
  margin-bottom: 38px;
  margin-left: -12px;
}

.guest-view-header {
  position: relative;
  /* Layout Properties */
  top: 0.0004820823669433594px;
  /* left: 200px; */
  width: 967px;
  height: 250px;
  /* width: 1087px; */
  /* UI Properties */
  background: var(--unnamed-color-260b19) 0% 0% no-repeat padding-box;
  background: #260B19 0% 0% no-repeat padding-box;
  background-image: url('../../assets/guest_view_header.png');
  background-position-x: 216px;

  border-radius: 0px 0px 5px 5px;
  opacity: 1;
}

.capifly-logo{
  position: absolute;
  top: 24px;
  left: 16px;
}

.guest-company-name{
  position: absolute;
/* Layout Properties */
  top: 32px;
  right: 78px;
  /* width: 62px; */
  height: 17px;
  /* UI Properties */
  color: var(--unnamed-color-bbe8e0);
  text-align: right;
  font: normal normal bold 14px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #BBE8E0;
  opacity: 1;
}

.guest-hello-1{
  position: absolute;
  /* Layout Properties */
  top: 89px;
  left: 84px;
  /* width: 157px; */
  height: 34px;
  /* UI Properties */
  color: var(--unnamed-color-bbe8e0);
  text-align: left;
  font: normal normal bold 26px/34px Roboto;
  letter-spacing: 0px;
  color: #BBE8E0;
  opacity: 1;
}

.guest-hello-2 {
  position: absolute;
  /* Layout Properties */
  top: 123px;
  left: 84px;
  /* width: 407px; */
  height: 34px;
  /* UI Properties */
  color: var(--unnamed-color-dfddd9);
  text-align: left;
  font: normal normal bold 26px/34px Roboto;
  letter-spacing: 0px;
  color: #DFDDD9;
  opacity: 1;
}

.guest-hello-3{
  position: absolute;
  top: 159px;
  left: 84px;
  width: 797px;
  height: 45px;
  color: var(--unnamed-color-dfddd9);
  text-align: left;
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0px;
  color: #DFDDD9;
  opacity: 1;
}

.guest-hello-3 > p {
  margin-bottom: 0px;
}

.guest-report-summary{
  margin-left: 0px;
  margin-right: 0px;
  margin-top:20px;
  position: relative;
}

.guest-report-summary>* {
  padding: 18px;
}
.summary-screening-score {
  /* Layout Properties */
  /* width: 233px; */
  height: 80px;
  /* UI Properties */
  background: var(--unnamed-color-e8dff5) 0% 0% no-repeat padding-box;
  background: #E8DFF5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 18px;;
  margin-right: 12px;
}

.guest-report-summary-title {
  color: var(--unnamed-color-411884);
  text-align: left;
  font: normal normal 500 14px/19px Roboto;
  letter-spacing: 0.42px;
  color: #411884;
  text-transform: uppercase;
  opacity: 1;
}


.guest-report-summary-value{
  height: 21px;
  /* UI Properties */
  color: var(--unnamed-color-411884);
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #411884;
  opacity: 1;
}

.guest-report-summary-col{
  height: 80px;
  /* UI Properties */
  background: var(--unnamed-color-fcfbfa) 0% 0% no-repeat padding-box;
  background: #FCFBFA 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.col-border::after {
  content: "";
  position: absolute;
  top: 12.5%;

  border-left: 1px solid #ccc;
  height: 75%;
  margin-left: -20px;
}
</style>
