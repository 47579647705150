<template>
  <div id="offer-letter-container">
    <div class="main-container">
      <div class="mb-2">
        <img src="/images/Group 4247.png" alt="Group 4247" />
      </div>
      <div class="mb-2" style="font-family: Roboto_Bold; font-size: 20px">
        {{ app_data.number }} is qualified for funding!
      </div>
      <div style="font-size: 18px">
        Congratulation! <br />
        We are happy to share that you are qualified for financing and now we
        are moving your application to the execution stage.
      </div>
    </div>
    <div class="upload-application-block" v-if="false">
      <div>
        <div>
          Please download the offer letter, sign it and upload it onto the
          platform.
        </div>
        <div style="text-decoration: underline">
          Download offer letter again
        </div>
      </div>

      <div>
        <base-button
          stylevariant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
        >
          DOWNLOAD OFFER LETTER
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["app_data"],
};
</script>

<style scoped>
#offer-letter-container .main-container {
  background-color: #e0fadc;
  border-radius: 5px;
  padding: 2rem;
  color: #1c9d0b;
  text-align: center;
  margin-bottom: 2rem;
}
#offer-letter-container .upload-application-block {
  padding: 25px 40px;
  background-color: #ffffff;
  color: #741b47;
  border-radius: 5px;
  box-shadow: 1px 2px 29px #1717241a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
</style>
