class APIError {
  /**
   *
   * @param {Object} error
   */
  constructor(error) {
    if (error.response) {
      this.status = error.response.status;
      this.reason = error.response.statusText;
      this.message = error.response.data.message;
      this.errors = error.response.data.details;
    } else {
      this.status = 0;
      this.reason = "Network Error";
      this.message = "Could not connect to the server.";
      this.errors = {};
    }
  }
}

class APIResponse {
  /**
   *
   * @param {Object} response
   */
  constructor(response) {
    this.status = response.status;
    this.reason = response.statusText;
    this.data = response.data;
  }
}

export { APIError, APIResponse };
