<template>
  <div id="application-canceled-container">
    <div class="main-container">
      <div class="mb-2" style="font-family: Roboto_Bold; font-size: 20px">
        Oops!
      </div>
      <div class="mb-2" style="font-size: 18px">
        Your application has been canceled. Please click below to submit a new
        application.
      </div>
      <div class="mb-2">
        <base-button
          type="submit"
          stylevariant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium; width: 245px;"
          @onClick="openSeprateLink"
        >
          SUBMIT A NEW APPLICATION
        </base-button>
      </div>

      <div class="help-link d-none">Need help?</div>

      <div
        v-if="false"
        style="
          color: #741b47;
          font-size: 15px;
          text-decoration: underline;
          cursor: pointer;
        "
      >
        Learn about our screening process
      </div>
    </div>
    <div class="upload-application-block" v-if="false">
      <div>
        <div>
          We have reviewed your 2nd application and need the
          <span style="font-family: Roboto_Bold"> following documents: </span>
        </div>
        <div style="font-family: Roboto_Bold">
          Bank Statement 2019 and Company Registration 2022.
        </div>
        <div>Please click on update your application to proceed.</div>
      </div>
      <div>
        <base-button
          stylevariant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
        >
          UPDATE YOUR APPLICATION
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["app_data"],
  methods: {
    openSeprateLink() {
      window.open("https://www.growthcapifly.com/upload-documents", "_blank");
    },
  },
};
</script>

<style scoped>
#application-canceled-container .main-container {
  background-color: #f7dfe3;
  border-radius: 5px;
  padding: 2rem;
  color: #d91f3c;
  text-align: center;
  margin-bottom: 2rem;
}
#application-canceled-container .upload-application-block {
  padding: 25px 40px;
  background-color: #ffffff;
  color: #741b47;
  border-radius: 5px;
  box-shadow: 1px 2px 29px #1717241a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
#application-canceled-container .help-link {
  font-family: Roboto_Medium;
  font-size: 15px;
  color: #511332;
  text-decoration: underline;
  cursor: pointer;
}
</style>
