<template>
  <div id="login-container">
    <div class="flex-container" v-if="state>-1" >
      <div class="form-container">
        <div class="logo-container">
          <img src="/images/Capifly_Logo.png" alt="Capifly_Logo" />
        </div>
        <div class="welcome-text">Log Into My Account</div>
        <validation-observer ref="observer">
          <b-alert
            :show="error && error.length ? true : false"
            variant="danger"
          >
            <div v-html="error || '&nbsp;'"></div>
          </b-alert>
          <form @submit.prevent="submitForm">
            <validation-provider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <base-input
                placeholder="Email"
                type="text"
                field="email"
                :value="email"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </validation-provider>

            <div v-if="state === 2">
              <validation-provider
                name="otp"
                rules="required|min:6|max:6"
                v-slot="{ errors }"
              >

                <base-input
                  placeholder="OTP"
                  type="number"
                  field="otp"
                  :value="otp"
                  @change-value="setValue"
                  :disabled="loading"
                  :is_invalid="errors && errors.length"
                  :helper_text="errors && errors.length ? errors[0] : ''"
                ></base-input>
              </validation-provider>
            </div>

            <base-button v-if="state===1"
                type="button"
                stylevariant="primary"
                cus_style="font-size: 15px; font-family: Roboto_Medium"
                :disabled="loading"
                :loading="loading"
                @onClick="requestOtp"
              >
                <span >Request PIN to login </span>
            </base-button>              
            <base-button v-if="state===2"
              type="submit"
              stylevariant="primary"
              cus_style="font-size: 15px; font-family: Roboto_Medium"
              :disabled="loading"
              :loading="loading"
              @onClick="submitForm"
            >

              <span v-if="loading">Logging In...</span>
              <span v-else>LOG IN {{Math.floor(OtpExpiry/60)}}:{{ OtpExpiry%60 }}</span>
            </base-button>


          

          </form>
          <div class="link-container">
            <div class="flex-container" style="justify-content: space-between">
              <div class="link" @click="openSeprateLink">
                Don’t have an account?
              </div>
              <div class="link" @click="changeLink('forgot-password')">
                Forgot password?
              </div>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
    <div class="flex-container" v-if="state<0">
      <b-alert  :show="true" variant="danger" >Invalid or expired link plese contact GrowthCapifly to have new link</b-alert>
    </div>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

import AuthService from "../../services/auth.service";
import { LOGINOTP} from "../../store/auth.module.js";
const OTP_EXPIRY = 300;

export default {
  name: "OtpLogin-Page",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      error: null,
      loading: true,
      email: "",
      otp: "",
      state:0,
      OtpExpiry:OTP_EXPIRY,
      OtpTimer:null
    };
  },
  methods: {
    countDown() {
      clearTimeout(this.OtpTimer)
      this.OtpTimer = setTimeout(()=>{
        this.OtpExpiry --;
        if (this.OtpExpiry > 0) {
          this.countDown();
        } else {
          this.state = 1;
        }
      }, 1000)
    },
    async requestOtp(msg, event) {
      if (event) {
        event.preventDefault()
      }
      this.loading = true;
      AuthService.requestOtp(this.$route.params.slug).then((resp) => {
        this.OtpExpiry = resp.data.seconds;
        this.countDown();
        this.state=2;
      })
      .catch((msg)=>{
        //TODO: recheck this, should we log messages!
        console.log(msg);
      })
      .finally(()=>{
        this.loading = false;
      })
    },
    async submitForm() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      const payload = {
        email: this.email,
        otp: this.otp,
        slug: this.$route.params.slug,
      };

      this.error = null;
      this.loading = true;
      this.state = 3;

      return this.$store
        .dispatch(LOGINOTP, payload)
        .then((data) => {
          return this.$router.push({ name: "Guest View" });
        })
        .catch(({ message }) => {
          this.error = message;
          this.state = 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setValue(val, field) {
      this[field] = val;
    },
    changeLink(name) {
      this.$router.push({ name });
    },
    openSeprateLink() {
      window.open("https://www.growthcapifly.com/get-funded", "_blank");
    },   
  },
  mounted() {
      AuthService.getGuestUserEmail(this.$route.params.slug).then((resp) => {
        if (!resp.data.email || resp.data.email.length < 6) {
          this.state = -1;
        }
        else  {
          this.state = 1;
          this.loading = false;
          this.email = resp.data.email;

        }
      })
      .catch((message)=>{ 
        this.state = -1;
        console.log("STATE NOW =" + this.state)
      })
      .finally(()=>{})
    }, 
};
</script>

<style scoped>
#login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#login-container .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#login-container .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}
#login-container .welcome-text {
  color: #5e595c;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2rem;
}
#login-container .form-container {
  background-color: #ffffff;
  box-shadow: 1px 2px 29px #1717241a;
  border-radius: 5px;
  padding: 50px;
  width: 500px;
}
#login-container .link-container {
  margin-top: 1rem;
}
#login-container .link-container .link {
  color: #8f8b8d;
  font-size: 15px;
  cursor: pointer;
}
#login-container .link-container .link:hover {
  text-decoration: underline;
}
</style>
