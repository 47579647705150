var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"main-sidebar"}},[_vm._m(0),(_vm.selected_app)?_c('div',{staticClass:"application-selection"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between",on:{"click":function($event){$event.stopPropagation();return _vm.toggle_menu.apply(null, arguments)}}},[_c('div',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.selected_app.number)+" "+_vm._s(_vm._f("moment")(_vm.selected_app.created_at,"MM-DD-YYYY"))+" ")]),_c('div',[_c('img',{style:(_vm.open_menu ? 'transform: rotate(180deg)' : ''),attrs:{"src":"/images/polygon.png","alt":"polygon"}})])]),(_vm.open_menu)?_c('div',{staticClass:"menu-list",staticStyle:{"left":"0","width":"280px","border-radius":"0px","padding":"0px"},attrs:{"id":"menu-container"}},_vm._l((_vm.applications),function(each,index){return _c('div',{key:index,class:[
            'menu-item',
            'd-flex',
            'align-items-center',
            'justify-content-between',
            { active: _vm.selected_app.id === each.id ? true : false },
          ],staticStyle:{"border":"1px solid #f1f0ee"},on:{"click":function($event){$event.stopPropagation();return _vm.select_app(each)}}},[_c('div',[_c('div',{staticStyle:{"color":"#5e595c","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(each.created_at,"MM-DD-YYYY"))+" ")]),_c('div',{staticClass:"com-application-id"},[_vm._v(_vm._s(each.number))])]),(each && each.state)?_c('div',{staticClass:"state-container"},[_c('div',{staticClass:"company-state",style:(`border: 1px solid ${
                each.state === 'SCREENING'
                  ? '#8f8b8d'
                  : each.state === 'GATHERING'
                  ? '#234F68'
                  : each.state === 'FUNDED'
                  ? '#741B47'
                  : each.state === 'QUALIFIED'
                  ? '#411884'
                  : each.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : each.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : each.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : each.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };color: ${
                each.state === 'SCREENING'
                  ? '#8f8b8d'
                  : each.state === 'GATHERING'
                  ? '#234F68'
                  : each.state === 'FUNDED'
                  ? '#741B47'
                  : each.state === 'QUALIFIED'
                  ? '#411884'
                  : each.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : each.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : each.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : each.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };`)},[(each.state === 'SCREENING')?_c('img',{attrs:{"src":"/images/screening-icon.png","alt":"screening-icon"}}):(each.state === 'GATHERING')?_c('img',{attrs:{"src":"/images/gathering-icon.png","alt":"gathering-icon"}}):(each.state === 'FUNDED')?_c('img',{attrs:{"src":"/images/funded-icon.png","alt":"funded-icon"}}):(each.state === 'QUALIFIED')?_c('img',{attrs:{"src":"/images/qualified-icon.png","alt":"qualified-icon"}}):(each.state === 'DISQUALIFIED')?_c('img',{attrs:{"src":"/images/not-qualified-icon.png","alt":"not-qualified-icon"}}):(each.state === 'EXECUTION')?_c('img',{attrs:{"src":"/images/execution-icon.png","alt":"execution-icon"}}):(each.state === 'COMPLETED')?_c('img',{attrs:{"src":"/images/completed-icon.png","alt":"completed-icon"}}):(each.state === 'CANCELED')?_c('img',{attrs:{"src":"/images/canceled-icon.png","alt":"canceled-icon"}}):_vm._e(),_c('div',{staticStyle:{"margin-top":"2px","margin-left":"5px"}},[_vm._v(" "+_vm._s(each.state)+" ")])])]):_vm._e()])}),0):_vm._e()]):_vm._e(),_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"tab-container"},[_c('div',{class:[
          'tab',
          _vm.activetab === index ? 'active' : '',
          tab.comming_soon ? 'comming-soon' : '',
        ],style:(tab.comming_soon || tab.notification_count
            ? 'justify-content: space-between'
            : ''),on:{"click":function($event){return _vm.selectTab(tab)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticClass:"tab-avtar",attrs:{"src":_vm.activetab === index
                ? `/images/${tab.active_icon}`
                : `/images/${tab.inactive_icon}`,"alt":tab.name}}),_c('div',{staticClass:"tab-name"},[_vm._v(_vm._s(tab.name))])]),(tab.comming_soon)?_c('div',{staticClass:"comming-soon-text"},[_vm._v("COMING SOON")]):(tab.notification_count)?_c('div',{staticClass:"notification-count"},[_vm._v(" "+_vm._s(tab.notification_count)+" ")]):_vm._e()])])}),_c('div',{staticClass:"bottom-container"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"avtar-container"},[(_vm.$store.getters.user.company.image)?_c('img',{attrs:{"src":_vm.$store.getters.user.company.image,"alt":"avtar"}}):_c('span',[_vm._v(_vm._s(_vm.$store.getters.user.company.name.substring(0, 1)))])]),_c('div',{staticClass:"user-email"},[_vm._v(" "+_vm._s(_vm.$store.getters.user.company.name)+" ")])]),_c('div',{staticClass:"link-container"},[_c('div',{staticClass:"link",on:{"click":function($event){return _vm.gotoLink('/logout')}}},[_vm._v("Log out")]),_c('div',{staticClass:"link",staticStyle:{"margin-left":"1rem"},on:{"click":function($event){return _vm.gotoLink('/profile?tab=Company Details')}}},[_vm._v(" Profile ")])])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-container"},[_c('img',{staticClass:"tab-avtar",attrs:{"src":"/images/sidebar-logo.png","alt":"sidebar-logo"}})])
}]

export { render, staticRenderFns }