<template>
  <div id="screening-letter-container">
    <div class="main-container">
      <div class="mb-2" style="font-family: Roboto_Bold; font-size: 20px">
        Welcome to Capifly
      </div>
      <div class="mb-4" style="font-size: 18px">
        {{ app_data.number }} is under review. <br />

        Thank you for sharing the requested information, the details you shared
        are exactly what we need to complete our assessment!.
      </div>

      <div class="mb-2">
        <img src="/images/Group 4246.png" alt="Group 4246" />
      </div>
      <div style="font-family: Roboto_Bold; font-size: 12px">
        Screening in progress
      </div>
      <div
        v-if="false"
        style="
          color: #741b47;
          font-size: 15px;
          text-decoration: underline;
          cursor: pointer;
        "
      >
        Learn about our screening process
      </div>
    </div>
    <div class="upload-application-block" v-if="false">
      <div>
        <div>
          We have reviewed your 2nd application and need the
          <span style="font-family: Roboto_Bold"> following documents: </span>
        </div>
        <div style="font-family: Roboto_Bold">
          Bank Statement 2019 and Company Registration 2022.
        </div>
        <div>Please click on update your application to proceed.</div>
      </div>
      <div>
        <base-button
          stylevariant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
        >
          UPDATE YOUR APPLICATION
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["app_data"],
};
</script>

<style scoped>
#screening-letter-container .main-container {
  background-color: #deedfc;
  border-radius: 5px;
  padding: 2rem;
  color: #006ce2;
  text-align: center;
  margin-bottom: 2rem;
}
#screening-letter-container .upload-application-block {
  padding: 25px 40px;
  background-color: #ffffff;
  color: #741b47;
  border-radius: 5px;
  box-shadow: 1px 2px 29px #1717241a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
</style>
