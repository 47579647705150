var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cohort-container"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"title col-md-7"},[_vm._v("Net Dollars Retention")]),_c('div',{staticClass:"col-md-5 justify-content-end d-flex"},[_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.cohorts-arr-modal",modifiers:{"cohorts-arr-modal":true}}],class:['info-icon']})])]),_c('div',{staticClass:"d-flex align-items-center cohort-table"},[_c('div',[_vm._m(0),_vm._l((_vm.cohorts_dates),function(d_each,index){return _c('div',{key:index,staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"cell",staticStyle:{"min-width":"75px","border-left":"0px solid #dfddd9"}},[_vm._v(" "+_vm._s(_vm._f("moment")(d_each,"MMM-YY"))+" ")]),_c('div',{staticClass:"cell",staticStyle:{"min-width":"105px","border-left":"0px solid #dfddd9"}},[_vm._v(" "+_vm._s(_vm.cohorts_data.find((rec) => rec.date == d_each).arr ? new Intl.NumberFormat().format( _vm.cohorts_data.find((rec) => rec.date == d_each).arr ): "")+" ")])])})],2),_c('div',{staticClass:"cohort-table-data"},[_c('div',{staticClass:"d-flex align-items-center"},_vm._l((_vm.cohorts_periods),function(each,index){return _c('div',{key:index,staticClass:"cell header-cell",staticStyle:{"border-top":"0px solid #dfddd9"}},[_vm._v(" "+_vm._s(each)+" ")])}),0),_vm._l((_vm.cohorts_dates),function(d_each,index){return _c('div',{key:index,staticClass:"d-flex align-items-center"},_vm._l((_vm.cohorts_periods),function(p_each,index){return _c('div',{key:index,staticClass:"cell",style:(`background-color:${_vm.get_record_color(
              _vm.cohorts_data.find(
                (rec) => rec.date == d_each && rec.period == p_each
              )
            )}`),domProps:{"innerHTML":_vm._s(
              _vm.get_record(
                _vm.cohorts_data.find(
                  (rec) => rec.date == d_each && rec.period == p_each
                )
              )
            )}})}),0)})],2)]),_c('b-modal',{attrs:{"title":"Help Modal","size":"md","centered":"","hide-footer":"","hide-header":"","id":"cohorts-arr-modal"}},[_c('chart-help-modal',{attrs:{"selected_chart":'Cohorts Charts',"theId":'cohorts-arr-modal'}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"cell header-cell",staticStyle:{"min-width":"75px"}},[_vm._v("Month")]),_c('div',{staticClass:"cell header-cell",staticStyle:{"min-width":"105px"}},[_vm._v("ARR USD")])])
}]

export { render, staticRenderFns }