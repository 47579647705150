import AuthService from "../services/auth.service";

import { JWTService, UserService } from "../storage";

export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGINOTP = "loginOtp";

const AUTHENTICATE = "authenticate";
const DEAUTHENTICATE = "deauthenticate";
const AUTHENTICATE_GUEST = "authenticate_guest";

export default {
  state: {
    authenticated: !!JWTService.getToken(),
    user: UserService.getUser(),
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
  },
  actions: {
    [LOGIN]({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        AuthService.login(credentials)
          .then(({ data }) => {
            commit(AUTHENTICATE, data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [LOGOUT]({ commit }) {
      commit(DEAUTHENTICATE);
    },
    [LOGINOTP] ({ commit } , credentials) {
      return new Promise((resolve, reject) => {
        AuthService.loginOtp(credentials)
          .then(({ data }) => {
            commit(AUTHENTICATE_GUEST, data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });      
    }
  },
  mutations: {
    [AUTHENTICATE](state, { token, user, company }) {
      state.authenticated = true;
      const data_user = { ...user, company };
      state.user = data_user;

      JWTService.setToken(token);
      UserService.setUser(data_user);
    },
    [AUTHENTICATE_GUEST](state, data) {
      state.authenticated = true;
      state.user = data;

      JWTService.setToken(data.token);
      delete data.token;
      UserService.setUser(data);
    },    
    [DEAUTHENTICATE](state) {
      state.authenticated = false;
      state.user = {};

      JWTService.removeToken();
      UserService.removeUser();
    },
  },
};
