<template>
  <div id="chart-container">
    <!-- <div style="border: 1px solid #dfddd9; margin: 2rem 0rem"></div> -->
    <div class="main-container">
      <b-row>
        <b-col md="7">
          <div style="position: relative; margin-bottom: 0.5rem">
            <div
              :class="[
                'chart-select',
                { active: open_chart_menu ? true : false },
                'd-flex',
                'align-items-center',
                'justify-content-between',
              ]"
              @click.stop="toggle_chart_menu"
            >
              <div>
                <div class="title">
                  {{ selected_chart_rec.name }}
                  <span v-if="selected_chart_rec.symbol">
                    {{ selected_chart_rec.symbol }}
                  </span>
                </div>
                <div>
                  <span
                    class="sub-title"
                    :style="
                      'background-color:' + selected_chart_rec.color + ';'
                    "
                  >
                    {{ selected_chart_rec.label }}
                  </span>
                </div>
              </div>
              <div>
                <img
                  src="/images/polygon.png"
                  alt="polygon"
                  :style="open_chart_menu ? 'transform: rotate(180deg)' : ''"
                />
              </div>
            </div>
            <common-menu
              custom_style="left: 0px; top: 80px;width: 525px;"
              :open_menu="open_chart_menu"
              :menu_items="
                app_data.charts.map((each) => {
                  return (
                    each.name + ' ' + (each.symbol ? `${each.symbol}` : '')
                  );
                })
              "
              :selected_item="selected_chart_rec"
              @select_item="select_chart"
            />
          </div>
          <div
            v-if="phase === 1 && false"
            class="range-selection d-flex align-items-center justify-content-between"
          >
            <div style="position: relative; flex: 1 auto">
              <div
                :class="[
                  'chart-select',
                  { active: open_range_1 ? true : false },
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                ]"
                @click.stop="toggle_range_1"
              >
                <div style="font-size: 15px">
                  <span style="color: #8f8b8d">From: </span>
                  <span style="color: #260b19">01-01-2021</span>
                </div>
                <div>
                  <img
                    src="/images/polygon.png"
                    alt="polygon"
                    :style="open_range_1 ? 'transform: rotate(180deg)' : ''"
                  />
                </div>
              </div>
              <common-menu
                custom_style="left: 0px; top: 48px;width: 120px;"
                :open_menu="open_range_1"
                :menu_items="date_range_items"
                :selected_item="selected_range_1"
                @select_item="select_range_1"
              />
            </div>
            <div style="margin: 0rem 1rem">-</div>
            <div style="position: relative; flex: 1 auto">
              <div
                :class="[
                  'chart-select',
                  { active: open_range_2 ? true : false },
                  'd-flex',
                  'align-items-center',
                  'justify-content-between',
                ]"
                @click.stop="toggle_range_2"
              >
                <div style="font-size: 15px">
                  <span style="color: #8f8b8d">To: </span>
                  <span style="color: #260b19">01-01-2021</span>
                </div>
                <div>
                  <img
                    src="/images/polygon.png"
                    alt="polygon"
                    :style="open_range_2 ? 'transform: rotate(180deg)' : ''"
                  />
                </div>
              </div>
              <common-menu
                custom_style="left: 0px; top: 48px;width: 120px;"
                :open_menu="open_range_2"
                :menu_items="date_range_items"
                :selected_item="selected_range_2"
                @select_item="select_range_2"
              />
            </div>
          </div>
        </b-col>
        <b-col md="5" class="d-flex justify-content-end">
          <div :class="['info-icon']" v-b-modal.charts-modal></div>        
          <div
            :class="[
              'icon1',
              {
                active: phase === 1 ? true : false,
              },
            ]"
            @click.stop="changePhase(1)"
          ></div>
          <div
            :class="[
              'icon2',
              {
                active: phase === 2 ? true : false,
              },
            ]"
            @click.stop="changePhase(2)"
          ></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="border: 1px solid #dfddd9; margin: 1rem 0rem"></div>
          <div>
            <div v-if="phase === 1">
              <div class="map-container">
                <div id="chart" style="height: 100%; width: 100%"></div>
              </div>
              <b-row class="mt-3">
                <b-col
                  v-for="(each, index) in selected_chart_rec.averages"
                  :key="index"
                >
                  <div class="map-tab">
                    <div class="benchmark">Last {{ each.period }} months</div>

                    <div class="percentage">
                      {{ each.value }}
                      <span v-if="selected_chart_rec['symbol']">{{
                        selected_chart_rec["symbol"]
                      }}</span>
                    </div>

                    <div
                      class="benchmark"
                      v-if="selected_chart_rec.benchmarks.length"
                    >
                      {{ getBenchmark(each.value) }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-if="phase === 2">
              <div
                class="d-flex align-items-center"
                v-if="selected_chart_rec.benchmarks.length"
              >
                <div
                  class="tab"
                  v-for="(benchmark, index) in selected_chart_rec.benchmarks"
                  :key="index"
                >
                  {{ benchmark.description }}
                </div>
              </div>
              <div
                class="d-flex align-items-center flex-wrap"
                style="margin-top: 1rem"
              >
                <div
                  v-for="(column, i) in filtered_columns"
                  :key="i"
                  class="d-flex align-items-center flex-wrap"
                >
                  <div
                    class="block"
                    v-for="(gist, j) in selected_chart_rec.gists"
                    :key="j"
                  >
                    <div class="date">{{ gist.date }}</div>

                    <div class="per">
                      {{ gist[column.name] }}
                      <span v-if="selected_chart_rec['symbol']">
                        {{ selected_chart_rec["symbol"] }}
                      </span>
                    </div>

                    <div
                      class="state"
                      v-if="selected_chart_rec.benchmarks.length"
                    >
                      {{ getBenchmark(gist[column.name]) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-modal title="Help Modal" size="md" centered hide-footer hide-header id="charts-modal" >
      <chart-help-modal :selected_chart="this.selected_chart_rec.name" :theId="'charts-modal'">
      </chart-help-modal>
    </b-modal>
  </div>
</template>

<script>
import ChartHelpModal from './HelpModal.vue';
let CanvasJS = require("../../lib/canvasjs.min"),
  chart_map;
CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS;

CanvasJS.addColorSet("customColorSet1", ["#93C7D6"]);

CanvasJS.addColorSet("customColorSet2", [
  "#43872A",
  "#59B438",
  "#BDE4B0",
  "#DAE182",
  "#F9EA84",
  "#FBAE00",
  "#FB7700",
  "#F8696B",
  "#D01F21",
]);

export default {
  components: {
    ChartHelpModal
  },
  props: ["app_data", "charts"],
  data() {
    return {
      phase: 1,
      open_chart_menu: false,
      selected_chart_rec: "",
      date_range_items: [
        "01-01-2021",
        "01-01-2021",
        "01-01-2021",
        "01-01-2021",
        "01-01-2021",
      ],
      open_range_1: false,
      selected_range_1: "",
      open_range_2: false,
      selected_range_2: "",
      filtered_columns: [],
    };
  },
  methods: {
    get_record(rec) {
      if (rec && rec.value !== null) {
        const val = parseInt(rec.value, 10);
        return `<span>${rec.value}%</span>`;
      } else return null;
    },
    get_record_color(rec) {
      if (rec && rec.value !== null) {
        const val = parseInt(rec.value, 10);
        const color =
          val > 130
            ? "#00B050"
            : val > 110
            ? "#92D050"
            : val > 100
            ? "#A9D08E"
            : val > 95
            ? "#F7FF90"
            : val > 85
            ? "#F7F190"
            : val > 75
            ? "#FFE699"
            : val > 65
            ? "#F4B084"
            : val > 51
            ? "#FF0000"
            : "#C00000";
        return color;
      } else return null;
    },
    changePhase(val) {
      if (val === 2) chart_map.destroy();
      this.phase = val;
    },
    toggle_chart_menu() {
      this.open_chart_menu = !this.open_chart_menu;
      this.open_range_1 = false;
      this.open_range_2 = false;
    },
    select_chart(menu_item) {
      const rec = this.app_data.charts.find((each) => {
        const chart_name =
          each.name + " " + (each.symbol ? `${each.symbol}` : "");
        return chart_name === menu_item;
      });
      if (rec) this.selected_chart_rec = rec;
      this.toggle_chart_menu();
    },
    toggle_range_1() {
      this.open_range_1 = !this.open_range_1;
      this.open_chart_menu = false;
    },
    select_range_1(menu_item) {
      this.selected_range_1 = menu_item;
      this.toggle_range_1();
    },
    toggle_range_2() {
      this.open_range_2 = !this.open_range_2;
      this.open_chart_menu = false;
    },
    select_range_2(menu_item) {
      this.selected_range_1 = menu_item;
      this.select_range_2();
    },
    getBenchmark(val) {
      for (let i = 0; i < this.selected_chart_rec.benchmarks.length; i++) {
        const element = this.selected_chart_rec.benchmarks[i];
        let label = element.label,
          condition = "";

        for (let j = 0; j < element.tests.length; j++) {
          const element1 = element.tests[j];
          condition += `${j > 0 ? " &&" : ""} ${val} ${element1.condition} ${
            element1.value
          }`;
        }

        if (condition && eval(condition)) {
          return label;
        }
      }
    },
    makeChart(chart_data) {
      chart_map = new CanvasJS.Chart(`chart`, {
        animationEnabled: true,
        dataPointWidth: 24,
        toolTip: {
          contentFormatter: (e) => {
            let benchmark = this.getBenchmark(e.entries[0].dataPoint.y);
            return (
              e.entries[0].dataPoint.y +
              (chart_data.symbol ? chart_data.symbol : "") +
              "(" +
              benchmark +
              ")"
            );
          },
        },
        axisX: {
          interval: 1,
          labelFontSize: 13,
          labelFontFamily: "Roboto_Regular",
          labelFontColor: "#5E595C",
          labelAngle: 130,
          labelFormatter: function (e) {
            return e.label ? e.label.toString().substr(0, 7) : "";
          },
        },
        axisY: {
          labelFontSize: 13,
          labelFontFamily: "Roboto_Regular",
          labelFontColor: "#5E595C",
          gridThickness: 0,
          labelFormatter: function (e) {
            return (
              (e.value
                ? e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0) +
              (chart_data.type === "STACKED-COLUMN" ? "$" : "") +
              (chart_data.symbol && chart_data.type !== "STACKED-COLUMN"
                ? chart_data.symbol
                : "")
            );
          },
          minimum: chart_data.name === "LTV:CAC Ratio" ? 0 : null,
        },
        axisY2: {
          labelFontSize: 13,
          labelFontFamily: "Roboto_Regular",
          labelFontColor: "#5E595C",
          gridThickness: 0,
          labelFormatter: function (e) {
            return (
              (e.value ? e.value : 0) +
              (chart_data.symbol && chart_data.name === "Net MRR Growth Rate"
                ? chart_data.symbol
                : "")
            );
          },
        },
        colorSet:
          chart_data.type !== "STACKED-COLUMN"
            ? "customColorSet1"
            : "customColorSet2",
        data: chart_data.columns
          .filter((each) =>
            chart_data.name === "SaaS Quick Ratio"
              ? each.name === "saas_quick_ratio"
              : chart_data.name === "Net MRR Growth Rate"
              ? each.name === "net_mrr_growth_rate"
              : null
          )
          .map((col) => {
            return {
              type: "line",
              legendText: col.label,
              markerType: "none",
              showInLegend: true,
              color: "#9BC2E6",
              indexLabelFontSize: 12,
              indexLabelFontFamily: "Roboto_Bold",
              indexLabelFontColor: "blue",
              axisYType:
                chart_data.type === "STACKED-COLUMN" ? "secondary" : "primary",
              dataPoints: [
                ...chart_data.gists.map((each) => {
                  return {
                    label: each.date,
                    y: each[col.name],
                  };
                }),
              ],
            };
          })
          .concat(
            chart_data.columns
              .filter((each) =>
                chart_data.name === "SaaS Quick Ratio"
                  ? each.name !== "saas_quick_ratio"
                  : chart_data.name === "Net MRR Growth Rate"
                  ? each !== "net_mrr_growth_rate"
                  : each.name
              )
              .map((col) => {
                return {
                  type:
                    chart_data.type === "AREA"
                      ? "area"
                      : chart_data.type === "LINE"
                      ? "line"
                      : chart_data.type === "COLUMN"
                      ? "column"
                      : chart_data.type === "STACKED-COLUMN"
                      ? "stackedColumn"
                      : "",
                  legendText: col.label,
                  toolTipContent:
                    chart_data.type === "STACKED-COLUMN" ? "{y} USD" : "",
                  markerType:
                    chart_data.name === "Months to Recover CAC" ||
                    chart_data.name === "LTV:CAC Ratio"
                      ? "none"
                      : chart_data.type === "LINE" || chart_data.type === "AREA"
                      ? "circle"
                      : "none",
                  showInLegend: true,
                  indexLabelFontSize: 12,
                  indexLabelFontFamily: "Roboto_Bold",
                  indexLabelFontColor: "blue",
                  dataPoints: [
                    ...chart_data.gists.map((each) => {
                      return {
                        label: each.date,
                        y: each[col.name],
                      };
                    }),
                  ],
                };
              })
          ),
      });

      chart_map.render();
    }
  },
  watch: {
    selected_chart_rec(chart_data) {
      this.filtered_columns = chart_data.columns.filter((each) =>
        chart_data.name === "SaaS Quick Ratio"
          ? each.name === "saas_quick_ratio"
          : chart_data.name === "Net MRR Growth Rate"
          ? each.name === "net_mrr_growth_rate"
          : each
      );

      setTimeout(() => {
        this.makeChart(chart_data);
      }, 500);
    },
    phase(val) {
      if (val === 1) {
        setTimeout(() => {
          this.makeChart(this.selected_chart_rec);
        }, 500);
      }
    },
  },
  mounted() {
    if (this.app_data && this.app_data.charts && this.app_data.charts.length)
      this.selected_chart_rec = this.app_data.charts[0];
  },
};
</script>

<style scoped>
#chart-container {
  margin-top: 20px;
}
#chart-container .main-container {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 1px 2px 29px #1717241a;
}
#chart-container .chart-select {
  border-radius: 10px;
  background-color: #efede900;
  border: 1px solid #dfddd9;
  padding: 0.5rem;
  cursor: pointer;
}
#chart-container .chart-select.active {
  background-color: #efede9;
  border-color: #43102a;
}
#chart-container .title {
  font-size: 20px;
  color: #260b19;
}
#chart-container .sub-title {
  padding: 0.2rem 0.5rem;
  font-size: 11px;
  font-family: Roboto_Bold;
  text-transform: uppercase;
  color: #260b19;
}
#chart-container .map-container {
  height: 400px;
  padding: 1rem 0rem;
}
#chart-container .tab {
  font-size: 13px;
  font-family: Roboto_Medium;
  border-radius: 5px;
  padding: 0.5rem;
  margin-right: 0.5rem;
  color: #5e595c;
  background-color: #f5f3ef;
}

#chart-container .map-tab {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #17172429;
  text-align: center;
  padding: 20px;
}
#chart-container .map-tab .percentage {
  font-size: 22px;
  font-family: Roboto_Bold;
  color: #93c7d6;
}

#chart-container .map-tab .benchmark {
  font-size: 15px;
  font-family: Roboto_Regular;
  color: #5e595c;
}
#chart-container .block {
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  text-align: center;
}
#chart-container .block .date {
  font-size: 15px;
  color: #5e595c;
}
#chart-container .block .per {
  font-size: 22px;
  font-family: Roboto_Bold;
  color: #93c7d6;
}
#chart-container .block .state {
  font-size: 15px;
  color: #5e595c;
}
#chart-container .icon1 {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: #fcfbfa;
  cursor: pointer;
  margin-right: 0.5rem;
  background-image: url(../../assets/chart-icon-1-inactive.png);
  background-repeat: no-repeat;
  background-position: center;
}
#chart-container .icon1.active,
#chart-container .icon1:hover {
  background-color: #dfddd9;
  background-image: url(../../assets/chart-icon-1.png);
}
#chart-container .icon2 {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: #fcfbfa;
  cursor: pointer;
  margin-right: 0.5rem;
  background-image: url(../../assets/chart-icon-2-inactive.png);
  background-repeat: no-repeat;
  background-position: center;
}
#chart-container .icon2.active,
#chart-container .icon2:hover {
  background-color: #dfddd9;
  background-image: url(../../assets/chart-icon-2.png);
}

#chart-container .info-icon {
  width: 40px;
  height: 40px;
/* UI Properties */
  background: var(--unnamed-color-e6f0fc) 0% 0% no-repeat padding-box;
  background: #E6F0FC 0% 0% no-repeat padding-box;
  background-image: url('../../assets/info_icon.png');  
  border-radius: 8px;
  opacity: 1;
  margin-right: 0.5rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
