<template>
  <div id="not-qualified-container">
    <div class="top-container">
      <div class="left">SCREENING SCORE</div>
      <div class="right">
        <div class="mb-2" style="font-family: Roboto_Bold; font-size: 20px">
          Needs Improvement
        </div>
        <div class="mb-2" style="font-size: 15px">
          <span style="font-family: Roboto_Bold">
            We can provide you with up to
            <span v-html="$amount(app_data.maximum)"></span>.
          </span>
          But first, you need to improve your low-performing metrics and apply
          for a new application.
        </div>
        <base-button
          type="submit"
          stylevariant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium; width: 245px;"
          @onClick="openSeprateLink"
        >
          SUBMIT A NEW APPLICATION
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotQualifiedPage",
  props: ["app_data"],
  methods: {
    openSeprateLink() {
      window.open("https://www.growthcapifly.com/upload-documents", "_blank");
    },
  },
};
</script>

<style scoped>
#not-qualified-container .top-container {
  background-color: #fae3d4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}
#not-qualified-container .left {
  text-align: left;
  flex: 0.4;
  color: #cc4e00;
  font-size: 14px;
  font-family: Roboto_Medium;
}
#not-qualified-container .right {
  text-align: right;
  flex: 0.6;
  color: #cc4e00;
}
#not-qualified-container .link {
  font-size: 15px;
  color: #741b47;
  text-decoration: underline;
}
#not-qualified-container .block {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #17172429;
  margin-bottom: 2rem;
}
#not-qualified-container .title {
  font-size: 24px;
  color: #260b19;
}
#not-qualified-container .sub-title {
  padding: 0.2rem 0.5rem;
  font-size: 11px;
  font-family: Roboto_Bold;
  text-transform: uppercase;
}
#not-qualified-container .map-container {
  height: 400px;
  padding: 1rem 0rem;
}
#not-qualified-container .legend_container {
  cursor: pointer;
}
#not-qualified-container .legend_container .label {
  font-size: 13px;
  color: #260b19;
  margin-right: 0.5rem;
}
#not-qualified-container .legend_container .value {
  border: 1px dashed #411884;
  width: 20px;
}
</style>
