import ResourceService from "./resource.service";
import Vue from "vue";

class EnumeratesService extends ResourceService {
  static get resource() {
    return "enums";
  }

  static countries(params) {
    const url = `${this.resource}/countries`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static currencies(params) {
    const url = `${this.resource}/currencies`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static states(params) {
    const url = `${this.resource}/states`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static types(params) {
    const url = `${this.resource}/types`;

    return new Promise((resolve, reject) => {
      Vue.axios
        .get(url, { params })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default EnumeratesService;
