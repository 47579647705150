<template>
  <div id="detail-container">
    <div v-if="querying">
      <common-loading />
    </div>
    <b-row v-else>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Name"
          type="text"
          :value="data && data.name"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Mobile Phone Number"
          type="text"
          :value="data && data.phone"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Website URL"
          type="text"
          :value="data && data.website"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Place of Operations"
          type="text"
          :value="data && data.country"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Date of Establishment"
          type="text"
          :value="data && data.established_at"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
      <b-col lg="6" sm="12">
        <base-input
          label="Company Business Type"
          type="text"
          :value="data && data.types && data.types.join(', ')"
          helper_text="This field cannot be edited"
          :helper_text_style="getInputStyle"
          readOnly
        ></base-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CompanyService from "@/services/company.service";
import EnumeratesService from "@/services/enumerates.service";

export default {
  emits: ["gotComData"],
  computed: {
    getInputStyle() {
      return `text-align:right;color:#8F8B8D;font-family:Roboto_Italic;font-size:13px;`;
    },
  },
  data() {
    return { error: null, querying: false, data: {}, countries: [] };
  },
  methods: {
    query(params = {}) {
      this.error = null;
      this.querying = true;

      CompanyService.query(params)
        .then((data) => {
          let temp_data = { ...data };

          if (temp_data.country && this.countries.length)
            temp_data.country = this.countries.find(
              (each) => each.id === temp_data.country
            ).name;

          this.data = temp_data;
          this.$emit("gotComData", temp_data);
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading profile.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
  },
  mounted() {
    EnumeratesService.countries()
      .then((data) => {
        if (data && data.length)
          this.countries = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.querying = false;
        this.query();
      });
  },
};
</script>

<style scoped>
#detail-container {
  padding: 1rem;
}
</style>
