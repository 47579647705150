import axios from "axios";

import Vue from "vue";
import VueAxios from "vue-axios";

import router from "../../router";

import { JWTService, UserService } from "../../storage";
import { APIError, APIResponse } from "./utils";

const CONTENT_TYPE = "application/json";
const PUBLIC_URLS = ["auth/refresh-token", "auth/verify-token", "auth/login"];

const isObject = (data, messages) => {
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (Array.isArray(data[key])) {
      for (let j = 0; j < data[key].length; j++) {
        const element = data[key][j];
        if (Array.isArray(element)) {
          isArray(element, messages);
        }
        if (typeof element === "object") {
          isObject(element, messages);
        }
        if (typeof element === "string") {
          const key_parts = key.split("_");
          let modified_key_parts = [];

          for (let i = 0; i < key_parts.length; i++) {
            let key_part = key_parts[i];
            key_part =
              key_part.charAt(0).toLocaleUpperCase() +
              key_part.slice(1, key_part.length);
            modified_key_parts.push(key_part);
          }

          let modifiedMessage = element
            .toLowerCase()
            .replace("this field", modified_key_parts.join(" "));

          modifiedMessage = modifiedMessage
            .toLowerCase()
            .replace("this value", modified_key_parts.join(" "));

          if (modifiedMessage?.length) {
            modifiedMessage =
              modifiedMessage.charAt(0).toLocaleUpperCase() +
              modifiedMessage.slice(1, modifiedMessage.length);
          }
          if (messages.findIndex((each) => each === modifiedMessage) < 0)
            messages.push(modifiedMessage);
        }
      }
    }
    if (typeof data[key] === "object") {
      isObject(data[key], messages);
    }
  }
};

const isArray = (data, messages) => {
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    if (Array.isArray(element)) {
      isArray(element, messages);
    }
    if (typeof element === "object") {
      isObject(element, messages);
    }
    if (typeof element === "string") {
      if (messages.findIndex((each) => each === element) < 0);
      messages.push(element);
    }
  }
};

class APIService {
  constructor() {
    let client = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: parseInt(process.env.VUE_APP_TIMEOUT),
      responseType: "json",
      headers: {
        accept: CONTENT_TYPE,
        "content-type": CONTENT_TYPE,
      },
    });

    client.interceptors.request.use(
      (config) => {
        this.setAuthHeader(config);

        return config;
      },
      (error) => {
        return Promise.reject(new APIError(error));
      }
    );

    client.interceptors.response.use(
      (response) => {
        return Promise.resolve(new APIResponse(response));
      },
      (error) => {
        if (!error.response) return Promise.reject(new APIError(error));

        if (error.response.status === 400) {
          let temp_error = { ...error };
          if (temp_error?.response?.data?.details) {
            const { details } = temp_error?.response?.data;
            let messages = [];
            if (typeof details === "object") {
              isObject(details, messages);
            }
            if (Array.isArray(details)) {
              isArray(details, messages);
            }

            temp_error.response.data.message =
              "<div>" + messages.join("</div><div>") + "</div>";
          }
          return Promise.reject(new APIError(temp_error));
        }

        if (error.response.status !== 401 || error.config.url === "auth/login")
          return Promise.reject(new APIError(error));

        // Logout user if token refresh didn't work.
        if (error.config.url === "auth/refresh-token") {
          JWTService.removeToken();
          UserService.removeUser();

          router.push({ name: "login" });

          return Promise.reject(new APIError(error));
        }

        let payload = {
          token: JWTService.getToken(),
        };

        return client
          .post("auth/refresh-token", payload)
          .then(({ data }) => {
            const config = error.config;

            JWTService.setToken(data.token);

            this.setAuthHeader(config);

            return new Promise((resolve, reject) => {
              client
                .request(config)
                .then((response) => {
                  resolve(new APIResponse(response));
                })
                .catch((error) => {
                  reject(new APIError(error));
                });
            });
          })
          .catch((error) => {
            console.debug(error);

            return Promise.reject(new APIError(error));
          });
      }
    );

    Vue.use(VueAxios, client);
  }

  /**
   *
   * @param {AxiosRequestConfig} config
   */
  setAuthHeader(config) {
    let token = JWTService.getToken();

    if (token && !PUBLIC_URLS.includes(config.url))
      config.headers["Authorization"] = `Bearer ${token}`;
  }
}

export default APIService;
