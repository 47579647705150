import Vue from "vue";
import Router from "vue-router";

import store from "./store";

import Login from "./pages/Auth/Login.vue";
import Logout from "./pages/Auth/Logout.vue";
import ForgotPassword from "./pages/Auth/ForgotPassword.vue";
import ResetPassword from "./pages/Auth/ResetPassword.vue";
import OtpLogin from "./pages/Auth/OtpLogin.vue";
import NotFound from "./pages/NotFound.vue";
import TheMainLayout from "./components/Layout/TheMainLayout.vue";
import Dashboard from "./pages/Dashboard/index.vue";
import GuestApplicationView from "./pages/GuestApplicationView/index.vue";
// import Applications from "./pages/Applications/index.vue";
import ApplicationDetail from "./pages/ApplicationDetail/index.vue";
import Repayments from "./pages/Repayments.vue";
// import Notifications from "./pages/Notifications.vue";
import Profile from "./pages/Profile/index.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    { path: "/", redirect: "/login" },
    {
      name: "login",
      meta: { title: "Login", requiresAuth: false },
      path: "/login",
      component: Login,
    },    
    {
      name: "Guest Login",
      meta: { title: "Login", requiresAuth: false },
      path: "/guest/:slug/login",
      component: OtpLogin,
    },
    {
      name: "Guest View",
      meta: {title: "Guest View", requiresAuth: true},
      path: "/guest/:slug",
      component: GuestApplicationView
    },
    {
      name: "logout",
      meta: { title: "Logout", requiresAuth: true },
      path: "/logout",
      component: Logout,
    },
    {
      name: "forgot-password",
      meta: { title: "Forgot Password?", requiresAuth: false },
      path: "/forgot-password",
      component: ForgotPassword,
    },
    {
      name: "reset-password",
      meta: { title: "Reset Password?", requiresAuth: false },
      path: "/reset-password/:slug",
      component: ResetPassword,
    },
    {
      path: "/",
      component: TheMainLayout,
      children: [
        {
          name: "Dashboard",
          meta: { title: "Dashboard", requiresAuth: true },
          path: "dashboard",
          component: Dashboard,
        },
        {
          name: "Applications Detail",
          meta: { title: "Applications Detail", requiresAuth: true },
          path: "dashboard/:id",
          component: ApplicationDetail,
        },
        {
          name: "Repayments",
          meta: { title: "Repayments", requiresAuth: true },
          path: "repayments",
          component: Repayments,
        },
        // {
        //   name: "Notifications",
        //   meta: { title: "Notifications", requiresAuth: true },
        //   path: "notifications",
        //   component: Notifications,
        // },
        {
          name: "Profile",
          meta: { title: "Profile", requiresAuth: true },
          path: "profile",
          component: Profile,
        },
      ],
    },
    {
      name: "not-found",
      meta: { title: "Not Found" },
      path: "/:notFound(.*)",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, _, next) => {
  window.document.title = to.meta.title
    ? `Capifly - ${to.meta.title}`
    : "Capifly";

  if (to.meta.requiresAuth && !store.getters.authenticated) {
    sessionStorage.clear();
    next({ name: "login", query: { next: to.fullPath } });
  } else if (!to.meta.requiresAuth && store.getters.authenticated) {
    next("/dashboard");
  } else if (to.path.length > 1) {
    next();
  } else if (store.getters.authenticated) {
    next("/dashboard");
  } else {
    sessionStorage.clear();
    next("/login");
  }
});

router.afterEach(() => {
  const element = document.getElementById("content-container");
  if (element) {
    element.scrollTop = 0;
  }
});

export default router;
