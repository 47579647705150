<template>
  <div id="main-sidebar">
    <div class="logo-container">
      <img
        class="tab-avtar"
        src="/images/sidebar-logo.png"
        alt="sidebar-logo"
      />
    </div>
    <div class="application-selection" v-if="selected_app">
      <div
        class="d-flex align-items-center justify-content-between"
        @click.stop="toggle_menu"
      >
        <div style="opacity: 0.5">
          {{ selected_app.number }}
          {{ selected_app.created_at | moment("MM-DD-YYYY") }}
        </div>
        <div>
          <img
            src="/images/polygon.png"
            alt="polygon"
            :style="open_menu ? 'transform: rotate(180deg)' : ''"
          />
        </div>
      </div>
      <div
        id="menu-container"
        class="menu-list"
        style="left: 0; width: 280px; border-radius: 0px; padding: 0px"
        v-if="open_menu"
      >
        <div
          v-for="(each, index) in applications"
          :class="[
            'menu-item',
            'd-flex',
            'align-items-center',
            'justify-content-between',
            { active: selected_app.id === each.id ? true : false },
          ]"
          style="border: 1px solid #f1f0ee"
          :key="index"
          @click.stop="select_app(each)"
        >
          <div>
            <div style="color: #5e595c; font-size: 14px">
              {{ each.created_at | moment("MM-DD-YYYY") }}
            </div>
            <div class="com-application-id">{{ each.number }}</div>
          </div>
          <div class="state-container" v-if="each && each.state">
            <div
              class="company-state"
              :style="`border: 1px solid ${
                each.state === 'SCREENING'
                  ? '#8f8b8d'
                  : each.state === 'GATHERING'
                  ? '#234F68'
                  : each.state === 'FUNDED'
                  ? '#741B47'
                  : each.state === 'QUALIFIED'
                  ? '#411884'
                  : each.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : each.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : each.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : each.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };color: ${
                each.state === 'SCREENING'
                  ? '#8f8b8d'
                  : each.state === 'GATHERING'
                  ? '#234F68'
                  : each.state === 'FUNDED'
                  ? '#741B47'
                  : each.state === 'QUALIFIED'
                  ? '#411884'
                  : each.state === 'DISQUALIFIED'
                  ? '#CC4E00'
                  : each.state === 'EXECUTION'
                  ? '#8F8B8D'
                  : each.state === 'COMPLETED'
                  ? '#1c9d0b'
                  : each.state === 'CANCELED'
                  ? '#260b19'
                  : ''
              };`"
            >
              <img
                v-if="each.state === 'SCREENING'"
                src="/images/screening-icon.png"
                alt="screening-icon"
              />
              <img
                v-else-if="each.state === 'GATHERING'"
                src="/images/gathering-icon.png"
                alt="gathering-icon"
              />
              <img
                v-else-if="each.state === 'FUNDED'"
                src="/images/funded-icon.png"
                alt="funded-icon"
              />
              <img
                v-else-if="each.state === 'QUALIFIED'"
                src="/images/qualified-icon.png"
                alt="qualified-icon"
              />
              <img
                v-else-if="each.state === 'DISQUALIFIED'"
                src="/images/not-qualified-icon.png"
                alt="not-qualified-icon"
              />
              <img
                v-else-if="each.state === 'EXECUTION'"
                src="/images/execution-icon.png"
                alt="execution-icon"
              />
              <img
                v-else-if="each.state === 'COMPLETED'"
                src="/images/completed-icon.png"
                alt="completed-icon"
              />
              <img
                v-else-if="each.state === 'CANCELED'"
                src="/images/canceled-icon.png"
                alt="canceled-icon"
              />
              <div style="margin-top: 2px; margin-left: 5px">
                {{ each.state }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-container" v-for="(tab, index) in tabs" :key="index">
      <div
        :class="[
          'tab',
          activetab === index ? 'active' : '',
          tab.comming_soon ? 'comming-soon' : '',
        ]"
        :style="
          tab.comming_soon || tab.notification_count
            ? 'justify-content: space-between'
            : ''
        "
        @click="selectTab(tab)"
      >
        <div style="display: flex; align-items: center">
          <img
            class="tab-avtar"
            :src="
              activetab === index
                ? `/images/${tab.active_icon}`
                : `/images/${tab.inactive_icon}`
            "
            :alt="tab.name"
          />
          <div class="tab-name">{{ tab.name }}</div>
        </div>
        <div class="comming-soon-text" v-if="tab.comming_soon">COMING SOON</div>
        <div class="notification-count" v-else-if="tab.notification_count">
          {{ tab.notification_count }}
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="user-info">
        <div class="avtar-container">
          <img
            v-if="$store.getters.user.company.image"
            :src="$store.getters.user.company.image"
            alt="avtar"
          />
          <span v-else>{{
            $store.getters.user.company.name.substring(0, 1)
          }}</span>
        </div>
        <div class="user-email">
          {{ $store.getters.user.company.name }}
        </div>
      </div>
      <div class="link-container">
        <div class="link" @click="gotoLink('/logout')">Log out</div>
        <div
          class="link"
          style="margin-left: 1rem"
          @click="gotoLink('/profile?tab=Company Details')"
        >
          Profile
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationsService from "../../services/applications.service";

export default {
  data() {
    return {
      activetab: 0,
      tabs: [
        {
          name: "Dashboard",
          active_icon: "applications-icon-active.png",
          inactive_icon: "applications-icon-inactive.png",
          link: `/dashboard`,
        },
        {
          name: "Repayments",
          active_icon: "payments-icon-active.png",
          inactive_icon: "payments-icon-inactive.png",
          link: "/repayments",
        },
        // {
        //   name: "Notifications",
        //   active_icon: "notification-icon-active.png",
        //   inactive_icon: "notification-icon-inactive.png",
        //   notification_count: 2,
        //   link: "/notifications",
        // },
      ],
      error: null,
      querying: false,
      applications: [],
      open_menu: false,
      selected_app: null,
    };
  },
  methods: {
    selectTab(tab) {
      if (!tab.comming_soon) {
        if (tab.link === "/dashboard" && this.selected_app)
          this.$router.push(`/dashboard/${this.selected_app.id}`);
        else this.$router.push(tab.link);
      }
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    toggle_menu() {
      this.open_menu = !this.open_menu;
    },
    select_app(val) {
      this.selected_app = val;
      this.toggle_menu();
      this.$router.push(`/dashboard/${val.id}`);
    },
    query(params = {}) {
      this.error = null;
      this.querying = true;
      this.applications = [];

      ApplicationsService.query(params)
        .then(({ count, objects }) => {
          this.applications = objects.map((item) => {
            return item;
          });

          this.selected_app = this.applications[0];

          if (this.$route.fullPath === "/dashboard")
            this.$router.push(`/dashboard/${this.selected_app.id}`);

          if (!count) this.error = "No applications found.";
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading applications.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    matchSelectedApp() {
      if (
        this.$route &&
        this.$route.params &&
        this.$route.params.id &&
        this.$route.fullPath.includes("/dashboard") &&
        this.selected_app &&
        this.selected_app.id &&
        this.selected_app.id !== this.$route.params.id
      ) {
        this.selected_app = this.applications.find(
          (each) => each.id === this.$route.params.id
        );
      }
    },
  },
  watch: {
    $route() {
      this.matchSelectedApp();
      const actualtab = this.tabs.findIndex((each) =>
        this.$route.fullPath.includes(each.link)
      );
      if (this.activetab !== actualtab) this.activetab = actualtab;
    },
  },
  mounted() {
    this.activetab = this.tabs.findIndex((each) =>
      this.$route.fullPath.includes(each.link)
    );
    this.query();
    this.matchSelectedApp();
  },
};
</script>

<style scoped>
#main-sidebar {
  height: 100vh;
  background-color: #ffffff;
  position: relative;
}
#main-sidebar .tab-container {
  width: 100%;
}
#main-sidebar .tab.active {
  background-color: #fff2cc;
}
#main-sidebar .tab.comming-soon {
  cursor: not-allowed;
}
#main-sidebar .tab {
  padding: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#main-sidebar .active .tab-name {
  color: #511332;
}
#main-sidebar .comming-soon .tab-name {
  color: #dfddd9;
}
#main-sidebar .tab-name {
  margin-left: 1rem;
  font-size: 16px;
  font-family: Roboto_Medium;
  color: #5e595c;
}
#main-sidebar .notification-count {
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #ffffff;
  background-color: #d91f3c;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#main-sidebar .comming-soon-text {
  font-size: 11px;
  font-family: Roboto_Medium;
  color: #bab8b9;
}
#main-sidebar .logo-container {
  background-color: #260b19;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#main-container .application-selection {
  background-color: #43102a;
  color: #ffffff;
  font-size: 14px;
  padding: 0.2rem 0.8rem;
  cursor: pointer;
  position: relative;
}
#main-container .com-application-id {
  font-size: 13px;
  color: #8f8b8d;
  font-family: "Roboto_Bold";
}
#main-container .state-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#main-container .company-state {
  padding: 0.2rem 0.5rem;
  font-size: 11px;
  font-family: "Roboto_Bold";
  border-radius: 5px;
  display: flex;
  align-items: center;
}
#main-sidebar .bottom-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #f0efed;
}
#main-sidebar .user-info {
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
}
#main-sidebar .avtar-container {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #ebdfe5;
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #260b19;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
#main-sidebar .user-email {
  margin-left: 1rem;
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #260b19;
}
#main-sidebar .link-container {
  padding: 0.5rem 1rem 1rem 1rem;
  display: flex;
  align-items: center;
}
#main-sidebar .link {
  cursor: pointer;
  font-size: 15px;
  color: #741b47;
}
#main-sidebar .link:hover {
  text-decoration: underline;
}
</style>
