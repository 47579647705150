<template>
  <div id="dashboard-container">
    <div class="back-link" @click.stop="goBack" v-if="false">
      <img src="/images/Component 593 – 171.png" alt="Component 593 – 171" />
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      style="margin-bottom: 25px"
    >
      <div class="title">
        Hello {{ $store.getters.user.first_name }}
        {{ $store.getters.user.last_name }}
      </div>
      <div
        v-if="data && (data.state === 'FUNDED' || data.state === 'COMPLETED')"
        class="new-app-link"
      >
        <span style="color: #260b19">Need more funding?</span>
        <span
          style="color: #741b47; text-decoration: underline; cursor: pointer"
          @click="openSeprateLink"
        >
          Start a new application
        </span>
      </div>
    </div>

    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>

    <div v-if="querying">
      <common-loading />
    </div>
    <div v-else-if="data && data.state">
      <offer-letter
        :app_data="data"
        :charts="data.charts"
        v-if="data.state === 'QUALIFIED' || data.state === 'EXECUTION'"
      />
      <offer-letter-1
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'OfferLetter1'"
      />
      <screening-stage
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'SCREENING'"
      />
      <Gathering
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'GATHERING'"
      />
      <not-qualified
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'DISQUALIFIED'"
      />
      <funded-stage
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'FUNDED' || data.state === 'COMPLETED'"
      />
      <application-canceled
        :app_data="data"
        :charts="data.charts"
        v-else-if="data.state === 'CANCELED'"
      />
      <div v-else class="text-center p-2">{{ data.state }}</div>
    </div>
    <charts-page
      v-if="
        !querying &&
        data &&
        data.state !== 'CANCELED' &&
        data.charts &&
        data.charts.length
      "
      :style="`opacity: ${data && data.state === 'CANCELED' ? 0.1 : 1}`"
      :app_data="data"
      :charts="data.charts"
    />
    <cohorts-arr
      v-if="data && data.cohorts && data.cohorts.length"
      :app_data="data"
    />

    <cohorts-logos
      v-if="data && data.cohorts && data.cohorts.length"
      :app_data="data"
    /> 
    <!-- <cohorts-page
      v-if="data && data.cohorts && data.cohorts.length"
      :app_data="data"
    /> -->
  </div>
</template>

<script>
import ApplicationsService from "@/services/applications.service";

import OfferLetter from "./OfferLetter.vue";
import OfferLetter1 from "./OfferLetter1.vue";
import ScreeningStage from "./ScreeningStage.vue";
import NotQualified from "./NotQualified.vue";
import FundedStage from "./FundedStage.vue";
import ApplicationCanceled from "./ApplicationCanceled.vue";

import ChartsPage from "../../ui-components/application/Charts.vue"
import Gathering from "./Gathering.vue";

import CohortsArr from "../../ui-components/application/CohortsArr.vue"
import CohortsLogos from "../../ui-components/application/CohortsLogos.vue"

export default {
  name: "Dashboard-Page",
  components: {
    OfferLetter,
    OfferLetter1,
    ScreeningStage,
    NotQualified,
    FundedStage,
    ApplicationCanceled,
    ChartsPage,
    CohortsArr,
    CohortsLogos,    
    // CohortsPage,
    Gathering,
  },
  data() {
    return {
      id: null,
      error: null,
      querying: false,
      data: null,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    query(id) {
      this.error = null;
      this.querying = true;
      this.data = [];

      ApplicationsService.get(id)
        .then((data) => {
          if (!data) {
            this.error = "No application found.";
          } else {
            this.data = data;
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading application.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    openSeprateLink() {
      window.open("https://www.growthcapifly.com/upload-documents", "_blank");
    },
  },
  watch: {
    $route(val) {
      if (!this.id || this.id !== val.params.id) {
        this.id = val.params.id;
        this.query(val.params.id);
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.query(this.$route.params.id);
  },
  updated() {
    if (!this.id || this.id !== this.$route.params.id) {
      this.id = this.$route.params.id;
      this.query(this.$route.params.id);
    }
  },
};
</script>

<style scoped>
#dashboard-container {
  padding: 60px;
}
#dashboard-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
}
#dashboard-container .new-app-link {
  font-size: 15px;
}
#dashboard-container .back-link {
  cursor: pointer;
  margin-bottom: 38px;
  margin-left: -12px;
}
</style>
