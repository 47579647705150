<template>
  <div id="change-password-container">
    <form @submit.prevent="save">
      <validation-observer ref="observer">
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <b-row>
          <b-col lg="6" sm="12">
            <validation-provider
              name="old password"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <base-input
                placeholder="Old Password"
                type="password"
                field="old_pass"
                :value="old_pass"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              >
              </base-input>
            </validation-provider>
          </b-col>
          <b-col lg="6" sm="12">
            <validation-provider
              name="new password"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <base-input
                placeholder="New Password"
                type="password"
                field="new_pass"
                :value="new_pass"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              >
              </base-input>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col offset-lg="7" lg="5" sm="12" class="d-flex align-items-center">
            <base-button
              type="submit"
              stylevariant="secondary"
              cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
              :disabled="loading"
              :loading="loading"
              @onClick="save"
            >
              <span v-if="loading">Changing...</span>
              <span v-else>CHANGE</span>
            </base-button>
            <base-button
              type="button"
              stylevariant="secondary"
              cus_style="font-size: 15px; font-family: Roboto_Medium"
              @onClick="cancel"
            >
              CANCEL
            </base-button>
          </b-col>
        </b-row>
      </validation-observer>
    </form>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

import AuthService from "@/services/auth.service";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      error: null,
      loading: false,
      old_pass: "",
      new_pass: "",
    };
  },
  methods: {
    async save() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      const payload = {
        old: this.old_pass,
        new: this.new_pass,
      };

      this.error = null;
      this.loading = true;

      AuthService.changePassword(payload)
        .then(() => {
          this.reset();
          this.$router.push("/logout");
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.push("/profile?tab=Company Details");
      this.reset();
    },
    reset() {
      if (this.$refs.observer) this.$refs.observer.reset();

      this.old_pass = "";
      this.new_pass = "";
    },
    setValue(val, field) {
      this[field] = val;
    },
  },
  computed: {
    getInputStyle() {
      return `text-align:right;color:#8F8B8D;font-family:Roboto_Italic;font-size:13px;`;
    },
  },
};
</script>

<style scoped>
#change-password-container {
  padding: 1rem;
}
#change-password-container .title {
  font-size: 24px;
  color: #260b19;
}
</style>
