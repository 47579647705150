<template>
  <div id="reset-password-container">
    <div class="flex-container">
      <div class="form-container">
        <div class="logo-container">
          <img src="/images/Capifly_Logo.png" alt="Capifly_Logo" />
        </div>
        <div class="welcome-text">Reset Password</div>

        <form @submit.prevent="submit">
          <validation-observer ref="observer">
            <b-alert
              :show="(error && error.length) || (success && success.length)"
              :variant="
                error && error.length
                  ? 'danger'
                  : success && success.length
                  ? 'success'
                  : 'primary'
              "
            >
              <div v-html="error || success || '&nbsp;'"></div>
            </b-alert>

            <validation-provider
              name="password"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <base-input
                placeholder="Password"
                type="password"
                field="new_password"
                :value="new_password"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
                cus_ref="password"
              ></base-input>
            </validation-provider>

            <validation-provider
              name="confirm password"
              rules="required|confirmed:password"
              v-slot="{ errors }"
            >
              <base-input
                placeholder="Confirm password"
                type="password"
                field="confirm_password"
                :value="confirm_password"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </validation-provider>

            <base-button
              type="submit"
              stylevariant="primary"
              cus_style="font-size: 15px; font-family: Roboto_Medium"
              @onClick="submit"
              :disabled="loading"
              :loading="loading"
            >
              <span v-if="loading">Sending...</span>
              <span v-else>SET PASSWORD</span>
            </base-button>
          </validation-observer>
        </form>
        <div class="link-container">
          <div class="flex-container">
            <div class="link" @click="changeLink('login')">Back to login</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

import AuthService from "@/services/auth.service";

export default {
  name: "Reset-Password-Page",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      error: null,
      success: null,
      loading: false,
      new_password: "",
      confirm_password: "",
    };
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      const payload = {
        password: this.confirm_password,
      };

      this.error = null;
      this.success = null;
      this.loading = true;

      AuthService.resetPassword(this.$route.params.slug, payload)
        .then(() => {
          this.success = `Password changed successfully.`;
          this.reset();
          this.$router.push({ name: "Login" });
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      if (this.$refs.observer) this.$refs.observer.reset();

      this.new_password = "";
      this.confirm_password = "";
    },
    setValue(val, field) {
      this[field] = val;
    },
    changeLink(name) {
      this.$router.push({ name });
    },
  },
  mounted() {
    this.error = null;
    this.loading = true;

    AuthService.verifyResetPasswordSlug(this.$route.params.slug)
      .then(() => {
        this.reset();
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#reset-password-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#reset-password-container .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#reset-password-container .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}
#reset-password-container .welcome-text {
  color: #5e595c;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2rem;
}
#reset-password-container .form-container {
  background-color: #ffffff;
  box-shadow: 1px 2px 29px #1717241a;
  border-radius: 5px;
  padding: 50px;
  width: 500px;
}
#reset-password-container .link-container {
  margin-top: 1rem;
}
#reset-password-container .link-container .link {
  color: #8f8b8d;
  font-size: 15px;
  cursor: pointer;
}
#reset-password-container .link-container .link:hover {
  text-decoration: underline;
}
</style>
