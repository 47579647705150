<template>
  <div id="repayments-container">
    <div class="title">Repayments</div>

    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>

    <b-row v-if="summary">
      <b-col v-if="summary.outstanding">
        <div
          class="block"
          style="
            background-color: #dfddd9;
            border-color: #dfddd9;
            color: #511332;
          "
        >
          <div class="state">OUTSTANDING</div>
          <div class="amount">
            <span v-html="$amount(summary.outstanding.amount)"></span>
          </div>
          <div class="count">
            {{ summary.outstanding.quantity }}
            {{ summary.outstanding.quantity > 1 ? "repayments" : "repayment" }}
          </div>
        </div>
      </b-col>
      <b-col v-if="summary.overdue">
        <div
          class="block"
          style="
            background-color: #f6edec;
            border-color: #edb3b9;
            color: #d91f3c;
          "
        >
          <div class="state">OVERDUE</div>
          <div class="amount">
            <span v-html="$amount(summary.overdue.amount)"></span>
          </div>
          <div class="count">
            {{ summary.overdue.quantity }}
            {{ summary.overdue.quantity > 1 ? "repayments" : "repayment" }}
          </div>
        </div>
      </b-col>
      <b-col v-if="summary.due">
        <div
          class="block"
          style="
            background-color: #eeede9;
            border-color: #c7c5c3;
            color: #5e595c;
          "
        >
          <div class="state">DUE THIS MONTH</div>
          <div class="amount">
            <span v-html="$amount(summary.due.amount)"></span>
          </div>
          <div class="count">
            {{ summary.due.quantity }}
            {{ summary.due.quantity > 1 ? "repayments" : "repayment" }}
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="content-table" v-if="loading">
      <common-loading />
    </div>

    <div class="content-table" v-else>
      <b-container class="filter-container">
        <b-row>
          <b-col
            lg="6"
            sm="12"
            class="flex-container"
            style="justify-content: flex-start"
          >
            <div
              v-for="(tab, index) in status_list"
              :key="'status_' + index"
              @click="selectTab(index)"
              :class="['tab', selected_status === index ? 'active-tab' : '']"
            >
              {{ tab }}
            </div>
            <div
              v-for="(tab, index) in state_list"
              :key="'state_' + index"
              @click="selectState(index)"
              :class="['tab', selected_state === index ? 'active-tab' : '']"
            >
              {{ tab }}
            </div>
          </b-col>
          <b-col
            lg="6"
            sm="12"
            class="flex-container"
            style="justify-content: flex-end"
          >
            <div style="position: relative">
              <base-button
                stylevariant="primary"
                :cus_style="getInputBoxStyle"
                @onClick="toggle_menu"
              >
                <img
                  src="/images/sort.png"
                  alt="sort"
                  style="margin-right: 0.5rem; height: 18px; width: 18px"
                />
                <span v-if="this.$route.query.application_id">
                  {{ this.$route.query.application_id.split(",").length }}
                  {{
                    this.$route.query.application_id.split(",").length > 1
                      ? "Applications"
                      : "Application"
                  }}
                </span>
                <span v-else> All Applications </span>
              </base-button>
              <common-menu
                custom_style="left: 0px; top: 50px;"
                :open_menu="open_menu"
                :menu_items="app_menu_items.map((each) => each.number)"
                :selected_item="selected_item"
                @select_item="select_item"
              />
            </div>

            <div id="date-range-container" class="d-flex">
              <date-range-picker opens="left" v-model="dateRange">
                <template #input="picker">
                  <span v-if="picker.startDate && picker.endDate">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </span>
                  <span v-else> Start - End </span>
                </template>
              </date-range-picker>
            </div>

            <div class="clear-link" @click.stop="clearFilters">Clear</div>
          </b-col>
        </b-row>
      </b-container>
      <div class="table-container" v-if="data && data.length">
        <b-row class="table-title">
          <b-col class="table-title-field">
            <div class="table-title-text">PAYMENT ID</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">STATUS</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">APPLICATION ID</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">DUE DATE</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
          <b-col class="table-title-field">
            <div class="table-title-text">DUE AMOUNT</div>
            <img
              class="sort-icon"
              src="/images/sort-icon.png"
              alt="sort-icon"
            />
          </b-col>
        </b-row>
        <b-row class="table-data" v-for="(each, index) in data" :key="index">
          <b-col>
            <div class="number">{{ each.number }}</div>
          </b-col>
          <b-col>
            <span
              :class="[
                'status',
                {
                  upcoming:
                    each && each.status && each.status === 'SCHEDULED'
                      ? true
                      : false,
                },
                {
                  overdue:
                    each && each.status && each.status === 'OVERDUE'
                      ? true
                      : false,
                },
                {
                  paid:
                    each && each.status && each.status === 'PAID'
                      ? true
                      : false,
                },
              ]"
            >
              <span v-if="each && each.status && each.status === 'PAID'">
                PAID ON {{ each.paid_at | moment("MMM DD, YYYY") }}
              </span>
              <span
                v-else-if="each && each.status && each.status === 'SCHEDULED'"
              >
                SCHEDULED
              </span>
              <span v-if="each && each.status && each.status === 'OVERDUE'">
                {{
                  Math.ceil(
                    Math.abs(new Date() - new Date(each.due_at)) /
                      (1000 * 60 * 60 * 24)
                  )
                }}
                DAYS OVERDUE
              </span>
            </span>
          </b-col>
          <b-col>
            <div class="number">{{ each.application.number }}</div>
          </b-col>
          <b-col>
            <div class="number">
              {{ each.due_at | moment("MM-DD-YYYY") }}
            </div>
          </b-col>
          <b-col class="amount-container">
            <div class="due-amount">
              <span v-html="$amount(each.amount)"></span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="bottom-container">
        <div class="pagination-container" v-if="total_count">
          <base-pagination
            :active_page="active_page"
            :total_page="total_page"
            :total_count="total_count"
            :page_size="page_size"
            @set-page="setPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import EnumeratesService from "@/services/enumerates.service";
import PaymentsService from "@/services/payments.service";
import ApplicationsService from "@/services/applications.service";

export default {
  name: "Repayments-Page",
  components: { DateRangePicker },
  filters: {
    date(val) {
      return val ? val.toLocaleDateString() : "";
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      dateRange: { startDate: null, endDate: null },
      open_menu: false,
      app_menu_items: [],
      selected_item: "",
      selected_status: 0,
      status_list: ["All Repayments", "Overdue", "Paid", "Scheduled"],
      selected_state: null,
      state_list: [],
      state_options: [],
      page_size: 5,
      active_page: 1,
      total_page: 0,
      total_count: 0,
      data: [],
      summary: [],
    };
  },
  methods: {
    setPage(page) {
      this.active_page = page;
    },
    setValue(val, field) {
      this[field] = val;
    },
    selectTab(index) {
      this.selected_status = index;
      this.selected_state = null;
    },
    selectState(index) {
      this.selected_status = null;
      this.selected_state = index;
    },
    clearFilters() {
      this.dateRange = { startDate: null, endDate: null };
      this.selected_item = 0;
      this.selected_status = 0;
      this.selected_state = null;
      this.$router.push("/repayments");
    },
    select_item(menu_item) {
      if (menu_item && menu_item !== "ALL") {
        const rec = this.app_menu_items.find(
          (each) => each.number === menu_item
        );
        if (rec) {
          let application_ids =
            this.$route.query &&
            this.$route.query.application_id &&
            this.$route.query.application_id.length
              ? this.$route.query.application_id.split(",")
              : [];

          const index = application_ids.findIndex((each) => each === rec.id);

          if (index >= 0) application_ids.splice(index, 1);
          else application_ids.push(rec.id);

          this.$router.push(
            `/repayments${
              application_ids.length
                ? `?application_id=${application_ids.join(",")}`
                : ``
            }`
          );
        }
      } else this.$router.push(`/repayments`);

      this.selected_item = menu_item;
      this.toggle_menu();
    },
    toggle_menu() {
      this.open_menu = !this.open_menu;
    },
    query(params = {}) {
      if (this.term) params["search"] = this.term;

      if (this.dateRange && this.dateRange.startDate)
        params["start"] = this.dateRange.startDate;

      if (this.dateRange && this.dateRange.endDate)
        params["end"] = this.dateRange.endDate;

      if (this.selected_status > 0)
        params["status"] =
          this.status_list[this.selected_status]?.toLocaleUpperCase();

      if (this.selected_state >= 0)
        params["state"] =
          this.state_list[this.selected_state]?.toLocaleUpperCase();

      if (this.$route.query.application_id) {
        params["application_id"] = this.$route.query.application_id;
      }

      params["page"] = this.active_page || 1;
      params["limit"] = this.page_size;

      this.error = null;
      this.loading = true;
      this.data = [];
      this.total_count = 0;

      PaymentsService.query(params)
        .then(({ count, objects }) => {
          if (!count) {
            this.error = "No payments found.";
          }
          this.total_count = count;
          this.data = objects.map((item) => {
            return item;
          });
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading payments.";
        })
        .finally(() => {
          this.loading = false;
        });

      this.error = null;
      this.loading = true;

      PaymentsService.summary(params)
        .then((data) => {
          this.summary = data;
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading payment summary.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    getInputBoxStyle() {
      return `margin-right: 1rem;
      border: 1px solid #DFDDD9;
      border-radius: 10px;
      background-color: transparent;
      color: #111111;
      font-size: 14px;
      margin-bottom: 0px;
      `;
    },
  },
  watch: {
    total_count(val) {
      this.total_page = Math.ceil(val / this.page_size);
    },
    state_options(options) {
      if (options && options.length) {
        this.data = this.data.map((each) => {
          if (each.state && options.find((rec) => rec.id === each.state))
            each.state = options.find((rec) => rec.id === each.state).name;
          return each;
        });
      }
    },
    active_page() {
      this.query();
    },
    selected_item() {
      this.active_page = 1;
      this.query();
    },
    selected_status() {
      this.active_page = 1;
      this.query();
    },
    selected_state() {
      this.active_page = 1;
      this.query();
    },
  },
  mounted() {
    this.loading = true;

    EnumeratesService.states({ type: "PAYMENT" })
      .then((data) => {
        if (data && data.length)
          this.state_options = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        ApplicationsService.query()
          .then(({ objects }) => {
            this.app_menu_items = objects;
          })
          .catch(({ message }) => {
            this.error = message;
          })
          .finally(() => {
            this.app_menu_items.reverse();
            this.app_menu_items.unshift({ id: "ALL", number: "ALL" });
            this.loading = false;
            this.query();
          });
      });
  },
};
</script>

<style scoped>
#repayments-container {
  padding: 120px 60px 60px 60px;
}
#repayments-container .title {
  font-size: 18px;
  color: #8f8b8d;
  font-family: "Roboto_Medium";
  border-bottom: 1px solid #dfddd9;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
#repayments-container .block {
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid;
}
#repayments-container .state {
  font-size: 14px;
  font-family: Roboto_Medium;
}
#repayments-container .amount {
  font-size: 16px;
  font-family: Roboto_Bold;
}
#repayments-container .count {
  font-size: 13px;
}
#repayments-container .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
#repayments-container .flex-container {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
#repayments-container .tab {
  color: #8f8b8d;
  font-size: 15px;
  font-family: Roboto_Medium;
  margin-right: 2rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
#repayments-container .tab:hover {
  border-bottom: 2px solid #8f8b8d;
}
#repayments-container .active-tab {
  color: #741b47;
  border-bottom: 2px solid #741b47;
}
#repayments-container .clear-link {
  cursor: pointer;
  color: #dfddd9;
  font-size: 15px;
  text-decoration: underline;
}
#repayments-container .table-container {
  padding: 1rem;
}
#repayments-container .table-title {
  background-color: #f5f3ef;
  border-radius: 5px;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}
#repayments-container .table-title-field {
  display: flex;
  align-items: center;
  border-right: 1px solid #e9e6e3;
}
#repayments-container .sort-icon {
  height: 10px;
  margin-left: 0.5rem;
  cursor: pointer;
}
#repayments-container .table-title-text {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #5e595c;
}
#repayments-container .table-data {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0px 1px 4px #17172429;
  align-items: center;
  margin-bottom: 1rem;
}
#repayments-container .due-date {
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #5e595c;
}
#repayments-container .status {
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 11px;
  font-family: Roboto_Bold;
}
#repayments-container .status.upcoming {
  background-color: #dfddd9;
  color: #5e595c;
  border: 1px solid #5e595c;
}
#repayments-container .status.overdue {
  background-color: #f7dfe3;
  color: #d91f3c;
  border: 1px solid #d91f3c;
}
#repayments-container .status.paid {
  background-color: #f6fdf4;
  color: #37a928;
  border: 1px solid #37a928;
}
#repayments-container .number {
  font-size: 14px;
}
#repayments-container .amount-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#repayments-container .due-amount {
  font-size: 14px;
  font-family: Roboto_Bold;
  color: #5e595c;
}
#repayments-container .bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0rem;
}
</style>

<style>
.vue-daterange-picker {
  margin-right: 1rem !important;
}

.reportrange-text {
  border: 1px solid #dfddd9 !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  padding: 10px 20px !important;
}
</style>
