<template>
  <div id="forgot-password-container">
    <div class="flex-container">
      <div class="form-container">
        <div class="logo-container">
          <img src="/images/Capifly_Logo.png" alt="Capifly_Logo" />
        </div>
        <div class="welcome-text">Forgot Password?</div>
        <validation-observer ref="observer">
          <b-alert
            :show="(error && error.length) || (success && success.length)"
            :variant="
              error && error.length
                ? 'danger'
                : success && success.length
                ? 'success'
                : 'primary'
            "
          >
            <div v-html="error || success || '&nbsp;'"></div>
          </b-alert>

          <form @submit.prevent="submit">
            <validation-provider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <base-input
                :disabled="loading"
                placeholder="Email"
                type="text"
                field="email"
                :value="email"
                @change-value="setValue"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              ></base-input>
            </validation-provider>

            <base-button
              type="submit"
              stylevariant="primary"
              cus_style="font-size: 15px; font-family: Roboto_Medium"
              @onClick="submit"
              :disabled="loading"
              :loading="loading"
            >
              <span v-if="loading">Sending...</span>
              <span v-else>SEND LINK</span>
            </base-button>
          </form>
          <div class="link-container">
            <div class="flex-container">
              <div class="link" @click="changeLink('login')">Back to login</div>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";

import AuthService from "@/services/auth.service";

export default {
  name: "Forgot-Password-Page",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      error: null,
      success: null,
      loading: null,
      email: "",
    };
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      const payload = {
        email: this.email,
      };

      this.error = null;
      this.success = null;
      this.loading = true;

      AuthService.forgotPassword(payload)
        .then(() => {
          this.success = `Email sent to ${payload.email}`;
          this.reset();
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 5000);
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      if (this.$refs.observer) this.$refs.observer.reset();

      this.email = null;
    },
    setValue(val, field) {
      this[field] = val;
    },
    changeLink(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped>
#forgot-password-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#forgot-password-container .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#forgot-password-container .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}
#forgot-password-container .welcome-text {
  color: #5e595c;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2rem;
}
#forgot-password-container .form-container {
  background-color: #ffffff;
  box-shadow: 1px 2px 29px #1717241a;
  border-radius: 5px;
  padding: 50px;
  width: 500px;
}
#forgot-password-container .link-container {
  margin-top: 1rem;
}
#forgot-password-container .link-container .link {
  color: #8f8b8d;
  font-size: 15px;
  cursor: pointer;
}
#forgot-password-container .link-container .link:hover {
  text-decoration: underline;
}
</style>
