<template>
  <div id="gathering-letter-container">
    <div class="main-container">
      <div class="mb-2" style="font-family: Roboto_Bold; font-size: 20px">
        Welcome to Capifly
      </div>
      <div class="mb-4" style="font-size: 18px">
        You are now in data collection stage, our data team will be in touch
        with you shortly.
      </div>
    </div>
    <div class="upload-application-block" v-if="false">
      <div>
        <div>
          We have reviewed your 2nd application and need the
          <span style="font-family: Roboto_Bold"> following documents: </span>
        </div>
        <div style="font-family: Roboto_Bold">
          Bank Statement 2019 and Company Registration 2022.
        </div>
        <div>Please click on update your application to proceed.</div>
      </div>
      <div>
        <base-button
          stylevariant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
        >
          UPDATE YOUR APPLICATION
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["app_data"],
};
</script>

<style scoped>
#gathering-letter-container .main-container {
  background-color: #deedfc;
  border-radius: 5px;
  padding: 2rem;
  color: #006ce2;
  text-align: center;
  margin-bottom: 2rem;
}
#gathering-letter-container .upload-application-block {
  padding: 25px 40px;
  background-color: #ffffff;
  color: #741b47;
  border-radius: 5px;
  box-shadow: 1px 2px 29px #1717241a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
</style>
