<template>
  <div id="company-detail-container">
    <div class="title">Profile</div>

    <div class="d-flex justify-content-between align-items-center mt-4 mb-4">
      <div class="d-flex align-items-center">
        <div class="avtar-container">
          <img
            v-if="$store.getters.user.image"
            :src="$store.getters.user.image"
            alt="avtar"
            height="100px"
            width="100px"
          />
          <span v-else>{{
            $store.getters.user.first_name.substring(0, 1)
          }}</span>
        </div>
        <div>
          <div class="com-name">
            {{ $store.getters.user.first_name }}
            {{ $store.getters.user.last_name }}
          </div>
          <div class="d-flex align-items-center" v-if="com_data">
            <div
              class="d-flex align-items-center justify-content-center"
              style="margin-right: 0.25rem; height: 20px; width: 20px"
            >
              <img src="/images/Path 840.png" alt="Path 840" />
            </div>
            <div class="com-place">{{ com_data.country }}</div>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="d-flex align-items-center justify-content-center"
              style="margin-right: 0.25rem; height: 20px; width: 20px"
            >
              <img src="/images/Path 841.png" alt="Path 841" />
            </div>
            <div class="com-email">{{ $store.getters.user.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <b-row v-if="false">
      <b-col lg="3" sc="6">
        <div class="block" style="background-color: #e8dff5; color: #411884">
          <div class="top-label">SCREENING SCORE</div>
          <div class="middle-label">Excellent</div>
          <div class="bottom-label">12% discount rate</div>
        </div>
      </b-col>
      <b-col lg="3" sc="6">
        <div class="block" style="background-color: #dfddd9; color: #741b47">
          <div class="top-label">FACILITY</div>
          <div class="middle-label">$100,000</div>
          <div class="bottom-label">Sale Price: $ 120,000</div>
        </div>
      </b-col>
      <b-col lg="3" sc="6">
        <div class="block" style="background-color: #dfddd9; color: #741b47">
          <div class="top-label">PAID</div>
          <div class="middle-label">$ 77,334 (43%)</div>
          <div class="bottom-label">
            <b-progress height="8px" style="margin: 5px 0px">
              <b-progress-bar
                style="background-color: #741b47"
                :value="45"
              ></b-progress-bar>
            </b-progress>
          </div>
        </div>
      </b-col>
      <b-col lg="3" sc="6">
        <div class="block" style="background-color: #dfddd9; color: #741b47">
          <div class="top-label">OUTSTANDING</div>
          <div class="middle-label">$ 42,666</div>
          <div class="bottom-label">2 remaining repayments</div>
        </div>
      </b-col>
    </b-row>
    <div class="content-table">
      <b-container class="filter-container">
        <b-row>
          <b-col md="11" class="flex-container">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              @click="selectTab(tab)"
              :class="['tab', selectedTab === tab ? 'active-tab' : '']"
            >
              {{ tab }}
            </div>
          </b-col>
        </b-row>
      </b-container>

      <detail-data
        v-if="selectedTab === 'Company Details'"
        @gotComData="gotComData"
      />
      <change-password v-else-if="selectedTab === 'Change Password'" />
    </div>
  </div>
</template>

<script>
import DetailData from "./DetailData.vue";
import ChangePassword from "./ChangePassword.vue";

export default {
  components: {
    DetailData,
    ChangePassword,
  },
  data() {
    return {
      selectedTab: "",
      com_data: null,
      tabs: ["Company Details", "Change Password"],
    };
  },
  methods: {
    selectTab(tab) {
      this.$router.push(`/profile?tab=${tab}`);
    },
    gotComData(data) {
      this.com_data = data;
    },
  },
  watch: {
    $route() {
      if (this.selectedTab !== this.$route.query.tab)
        this.selectedTab = this.$route.query.tab;
    },
  },
  mounted() {
    this.selectedTab = this.$route.query.tab;
  },
};
</script>

<style scoped>
#company-detail-container {
  padding: 120px 60px 60px 60px;
}
#company-detail-container .title {
  font-size: 18px;
  color: #8f8b8d;
  font-family: "Roboto_Medium";
  border-bottom: 1px solid #dfddd9;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
#company-detail-container .back-link {
  font-size: 13px;
  color: #8f8b8d;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#company-detail-container .avtar-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #ebdfe5;
  font-size: 32px;
  font-family: Roboto_Medium;
  color: #260b19;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-right: 1rem;
}
#company-detail-container .com-name {
  font-size: 18px;
  font-family: Roboto_Bold;
  color: #260b19;
}
#company-detail-container .com-place {
  font-size: 15px;
  color: #8f8b8d;
  margin: 0.2rem 0rem;
}
#company-detail-container .com-email {
  font-size: 15px;
  text-decoration: underline;
  color: #741b47;
}
#company-detail-container .com-state {
  font-size: 11px;
  font-family: Roboto_Bold;
  color: #234f68;
  border: 1px solid #234f68;
  border-radius: 5px;
  padding: 0rem 0.2rem;
}
#company-detail-container .app-status {
  font-size: 12px;
  font-family: Roboto_Italic;
  color: #5e595c;
  margin: 0.2rem 0rem;
}
#company-detail-container .need-updates {
  font-size: 15px;
  font-family: Roboto_Regular;
  color: #741b47;
  text-decoration: underline;
  width: 160px;
  margin-right: 10px;
  cursor: pointer;
}
#company-detail-container .block {
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
#company-detail-container .top-label {
  font-size: 14px;
  font-family: Roboto_Medium;
}
#company-detail-container .middle-label {
  font-size: 16px;
  font-family: Roboto_Bold;
}
#company-detail-container .bottom-label {
  font-size: 13px;
}
#company-detail-container .content-table {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
#company-detail-container .flex-container {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
#company-detail-container .tab {
  color: #8f8b8d;
  font-size: 15px;
  font-family: Roboto_Medium;
  margin-right: 2rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
#company-detail-container .tab:hover {
  border-bottom: 2px solid #8f8b8d;
}
#company-detail-container .active-tab {
  color: #741b47;
  border-bottom: 2px solid #741b47;
}
#company-detail-container .action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#company-detail-container .edit-data-container {
  margin-top: 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
}
</style>
