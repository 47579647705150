<template>
  <div id="funded-container">
    <div class="top-container" v-if="show_top_block">
      <div class="close-icon" @click="close">
        <img src="/images/green-close.png" alt="green-close" />
      </div>
      <div class="mb-1" style="font-family: Roboto_Bold; font-size: 20px">
        Congratulations!
      </div>
      <div style="font-size: 18px">
        {{ app_data.number }} has successfully been funded with
        <span v-html="$amount(app_data.net)"></span>
      </div>
    </div>
    <div>
      <b-row class="align-self-stretch">
        <b-col lg="6" sm="12">
          <div
            class="h-100 left d-flex align-items-center justify-content-between"
          >
            <div style="font-size: 14px; font-family: Roboto_Medium">
              SCREENING SCORE
            </div>
            <div style="text-align: right">
              <div
                style="font-family: Roboto_Bold; font-size: 20px"
                v-if="app_data.score"
              >
                {{ app_data.score.substr(0, 1).toUpperCase()
                }}{{
                  app_data.score.substr(1, app_data.score.length).toLowerCase()
                }}
              </div>
              <div style="font-size: 15px">
                <span style="font-family: Roboto_Bold"
                  >{{ app_data.discount }}%</span
                >
                discount rate
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6" sm="12">
          <div
            class="h-100 right d-flex align-items-center justify-content-between"
          >
            <div style="font-size: 14px; font-family: Roboto_Medium">
              FACILITY
            </div>
            <div style="text-align: right">
              <div
                style="
                  color: #260b19;
                  font-size: 20px;
                  font-family: Roboto_Bold;
                "
              >
                <span v-html="$amount(app_data.growth)"></span>
              </div>
              <div class="mb-1" style="color: #260b19; font-size: 15px">
                Paid: <span v-html="$amount(app_data.paid)"></span> ({{
                  app_data.progress
                }}%)
              </div>
              <b-progress height="10px" style="width: 260px">
                <b-progress-bar
                  style="background-color: #741b47"
                  :value="app_data.progress"
                ></b-progress-bar>
              </b-progress>
              <div
                class="d-flex align-items-center justify-content-end mt-1"
                style="font-size: 15px"
              >
                <div
                  style="
                    color: #5e595c;
                    font-family: Roboto_Italic;
                    margin-right: 0.5rem;
                  "
                >
                  {{ app_data.scheduled }} remaining repayments
                </div>
                <div
                  style="text-decoration: underline; cursor: pointer"
                  @click.stop="
                    goToLink(`/repayments?application_id=${app_data.id}`)
                  "
                >
                  Details
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="tab-container">
      <b-row class="d-flex align-items-center">
        <b-col class="d-flex align-items-center justify-content-center">
          <img class="tab-icon" src="/images/arr-icon.png" alt="arr-icon" />
          <div class="tab-label">
            ARR: <span v-html="$amount(app_data.arr)"></span>
          </div>
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-center"
          style="
            border: 1px solid #dfddd9;
            border-top: none;
            border-bottom: none;
          "
        >
          <img
            class="tab-icon"
            src="/images/active-contracts-icon.png"
            alt="active-contracts-icon"
          />
          <div class="tab-label">
            Active Contracts: {{ app_data.contracts }}
          </div>
        </b-col>
        <b-col
          v-if="app_data.state === 'FUNDED'"
          class="d-flex align-items-center justify-content-center"
        >
          <img
            class="tab-icon"
            src="/images/investment-rounds-icon.png"
            alt="investment-rounds-icon"
          />
          <div class="tab-label">
            Investment Rounds: {{ app_data.investments }}
          </div>
        </b-col>
        <b-col
          v-if="app_data.state === 'COMPLETED'"
          class="d-flex align-items-center justify-content-center"
        >
          <img
            class="tab-icon"
            src="/images/shariah-compliant-icon.png"
            alt="shariah-compliant-icon"
          />
          <div class="tab-label">Shariah Compliant</div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "FundedStage",
  props: ["app_data"],
  data() {
    return {
      show_top_block: true,
    };
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    close() {
      this.show_top_block = false;
    },
  },
  mounted() {
    if (
      this.app_data &&
      this.app_data.state &&
      this.app_data.state === "COMPLETED"
    ) {
      this.show_top_block = false;
    }
  },
};
</script>

<style scoped>
#funded-container .top-container {
  background-color: #e0fadc;
  border-radius: 5px;
  padding: 1rem;
  color: #1c9d0b;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
}
#funded-container .top-container .close-icon {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  cursor: pointer;
}
#funded-container .left {
  background-color: #e8dff5;
  padding: 1rem;
  color: #411884;
  border-radius: 5px;
}
#funded-container .right {
  background-color: #dfddd9;
  padding: 1rem;
  color: #741b47;
  border-radius: 5px;
}
#funded-container .block {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #17172429;
  margin-bottom: 1rem;
}
#funded-container .title {
  font-size: 24px;
  color: #260b19;
}
#funded-container .sub-title {
  padding: 0.2rem 0.5rem;
  font-size: 11px;
  font-family: Roboto_Bold;
  text-transform: uppercase;
}
#funded-container .map-container {
  height: 400px;
  padding: 1rem 0rem;
}
#funded-container .legend_container {
  cursor: pointer;
}
#funded-container .legend_container .label {
  font-size: 13px;
  color: #260b19;
  margin-right: 0.5rem;
}
#funded-container .legend_container .value {
  border: 1px dashed #411884;
  width: 20px;
}
#funded-container .tab-container {
  background-color: #fcfbfa;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
}
#funded-container .tab-icon {
  margin-right: 0.5rem;
}
#funded-container .tab-label {
  color: #511332;
  font-size: 16px;
  font-family: Roboto_Bold;
}
</style>
