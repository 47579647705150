<template>
  <div id="cohort-container">
    <div class="row">
      <div class="title col-md-7">Net Dollars Retention</div>
      <div class="col-md-5 justify-content-end d-flex">
        <div
              :class="['info-icon']"
              v-b-modal.cohorts-arr-modal
          ></div>
          </div>
    </div> 
    <div class="d-flex align-items-center cohort-table">
      <div>
        <div class="d-flex align-items-center">
          <div style="min-width: 75px" class="cell header-cell">Month</div>
          <div style="min-width: 105px" class="cell header-cell">ARR USD</div>
        </div>
        <div
          class="d-flex align-items-center"
          v-for="(d_each, index) in cohorts_dates"
          :key="index"
        >
          <div
            style="min-width: 75px; border-left: 0px solid #dfddd9"
            class="cell"
          >
            {{ d_each | moment("MMM-YY") }}
          </div>
          <div
            style="min-width: 105px; border-left: 0px solid #dfddd9"
            class="cell"
          > {{
              cohorts_data.find((rec) => rec.date == d_each).arr
                ? new Intl.NumberFormat().format(
                    cohorts_data.find((rec) => rec.date == d_each).arr
                ): ""
            }}
          </div>
        </div>
      </div>
      <div class="cohort-table-data">
        <div class="d-flex align-items-center">
          <div
            class="cell header-cell"
            style="border-top: 0px solid #dfddd9"
            v-for="(each, index) in cohorts_periods"
            :key="index"
          >
            {{ each }}
          </div>
        </div>
        <div
          class="d-flex align-items-center"
          v-for="(d_each, index) in cohorts_dates"
          :key="index"
        >
          <div
            class="cell"
            v-for="(p_each, index) in cohorts_periods"
            :key="index"
            :style="`background-color:${get_record_color(
              cohorts_data.find(
                (rec) => rec.date == d_each && rec.period == p_each
              )
            )}`"
            v-html="
              get_record(
                cohorts_data.find(
                  (rec) => rec.date == d_each && rec.period == p_each
                )
              )
            "
          ></div>
        </div>
      </div>
    </div>

    <b-modal title="Help Modal" size="md" centered hide-footer hide-header id="cohorts-arr-modal" >
      <chart-help-modal :selected_chart="'Cohorts Charts'" :theId="'cohorts-arr-modal'">
      </chart-help-modal>
    </b-modal>    
  </div>
</template>

<script>
import ChartHelpModal from './HelpModal.vue';
export default {
  components: {
    ChartHelpModal
  },  
  props: ["app_data"],
  data() {
    return { cohorts_dates: [], cohorts_periods: [], cohorts_data: [] };
  },
  methods: {
    get_record(rec) {
      if (rec && rec.value !== null) {
        const val = parseInt(rec.value, 10);
        return `<span>${rec.value}%</span>`;
      } else return null;
    },
    get_record_color(rec) {
      if (rec && rec.value !== null) {
        const val = parseInt(rec.value, 10);
        const color = val > 130
            ? "#43872A"
            : val > 110
            ? "#59B438"
            : val > 100
            ? "#BDE4B0"
            : val > 95
            ? "#DAE182"
            : val > 85
            ? "#F9EA84"
            : val > 75
            ? "#FBAE00"
            : val > 65
            ? "#FB7700"
            : val > 51
            ? "#F8696B"
            : "#D01F21";
        return color;
      } else return null;
    }
  },
  mounted() {
    if (this.app_data.cohorts.length) {
      this.cohorts_data = this.app_data.cohorts;

      const data_date = this.app_data.cohorts.reduce(function (r, a) {
        r[a.date] = r[a.date] || [];
        r[a.date].push(a);
        return r;
      }, Object.create(null));
      this.cohorts_dates = Object.keys(data_date);

      const data_period = this.app_data.cohorts.reduce(function (r, a) {
        r[a.period] = r[a.period] || [];
        r[a.period].push(a);
        return r;
      }, Object.create(null));
      this.cohorts_periods = Object.keys(data_period);
    }
  },
};
</script>

<style scoped>
#cohort-container {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 2rem;
  box-shadow: 1px 2px 29px #1717241a;
}

#cohort-container .title {
  font-size: 24px;
  color: #260b19;
  padding: 0.5rem;
}
#cohort-container .cohort-table .cell {
  min-width: 60px;
  height: 40px;
  border-bottom: 0px solid #dfddd9;
  border-right: 0px solid #dfddd9;
  padding: 0.5rem;
  color: #260b19;
  font-size: 14px;
  font-family: Roboto_Regular;
}

#cohort-container .cohort-table .header-cell {
  border-left: 0px solid #dfddd9;
  border-top: 0px solid #dfddd9;
  color: #8f8b8d;
  font-size: 15px;
  font-family: Roboto_Medium;
}

#cohort-container .cohort-table .cohort-table-data {
  overflow: overlay;
}

#cohort-container .cohort-table .cohort-table-data::-webkit-scrollbar {
  width: 0px;
}

#cohort-container .info-icon {
  width: 40px;
  height: 40px;
/* UI Properties */
  background: var(--unnamed-color-e6f0fc) 0% 0% no-repeat padding-box;
  background: #E6F0FC 0% 0% no-repeat padding-box;
  background-image: url('../../assets/info_icon.png');  
  border-radius: 8px;
  opacity: 1;
  margin-right: 0.5rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
