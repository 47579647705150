<template>
  <div id="dashboard-container">
    <common-loading v-if="querying"></common-loading>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
  </div>
</template>

<script>
import ApplicationsService from "../../services/applications.service";

export default {
  data() {
    return { error: null, querying: null };
  },
  methods: {
    query(params = {}) {
      this.error = null;
      this.querying = true;

      ApplicationsService.query(params)
        .then(({ count, objects }) => {
          if (!count) {
            this.error = "No application found.";
          } else {
            this.$router.push(`/dashboard/${objects[0].id}`);
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading application.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
  },
  mounted() {
    this.query();
  },
};
</script>

<style scoped>
#dashboard-container {
  padding: 120px 60px;
}
</style>
